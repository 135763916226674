.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.fade-fast-enter-active,
.fade-fast-leave-active {
  transition: opacity .2s;
}

.fade-fast-enter,
.fade-fast-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
