.folder-color {
  color: #8f8f8f;
}

.folder-list-item {
  background: #fff;
  position: relative;
}

.folder-list-item:nth-child(odd) {
  background: #f4f4f4;
}

.folder-list-item, .fli {
  padding: 0 10px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &:hover {
    background: #d5dbe5;
    /*color: #fff;*/
  }
}

.folder-list-item--folder.last {
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
}

.fli__content {
  padding: 0;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: space-between;
}

.fli__column {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  padding: 5px;
  margin: 0;
  align-items: center;
  text-decoration: none;

  &.name i {
    margin-right: 5px;
  }

  &.name {
    min-width: 120px;
    flex: 1 1 auto;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-wrap: nowrap;

    .folder-list-item--session & {
      flex-basis: 100%;

      @include media-breakpoint-up(md) {
        flex: 1 1 auto;
      }
    }
  }

  &.actions {
    flex: 0 0 60px;
    width: 60px;
    text-align: center;
    padding: 0;
    justify-content: end;

    .btn {
      padding: 5px;
    }
  }
}

.folder-list-item--folder {

}

.folder-list-item--session {
  .start_date {
    width: 100px;
    flex: 0 1 100px;
    min-width: 100px;
  }

  .client {
    min-width: 100px;
    flex: 0 0 100px;
  }

  .start_date, .collaborators {
    width: 100px;
    flex: 0 0 100px;
    min-width: 100px;
  }

  .updated_at {
    text-align: right;
    width: 115px;
    flex: 0 0 115px;
    padding-right: 0;
    min-width: 60px;

    /*@include media-breakpoint-up(md) {
      flex: 1 1 auto;
    }*/
  }
}

.fli__collaborators {
  min-height: 30px;
  display: flex;
  align-items: center;
}

.fli__collaborator {
  position: relative;
  flex-shrink: 1;
  flex-basis: 20px;
  min-height: 18px;
}

.fli__collaborator-avatar {
  display: block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  object-fit: cover;
}
