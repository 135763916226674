$sidebar-session-width: 300px;
$sidebar-session-width-md: 360px;

.sidebar-session {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: $sidebar-session-width;
  background: #fff;
  margin-right: -$sidebar-session-width;
  //transition: margin-right 0.2s;
  transition: none;
  top: 55px;
  right: 0;
  z-index: 1017;
  height: calc(100vh - 55px);
  border-left: 1px solid #c8ced3;

  @include media-breakpoint-up(md) {
    width: $sidebar-session-width-md;
    margin-right: -$sidebar-session-width-md;
  }

  .sidebar-session-show & {
    margin-right: 0;
  }

  .nav-tabs .nav-item:first-child .nav-link {
    border-left: 0;
  }

  .nav-tabs .nav-link {
    border-top: none;
    border-radius: 0;
    padding: .75rem 1rem;
  }

  .tab-content .tab-pane {
    padding: 0;
  }

  .tab-content {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border: 0;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .tab-content {
    margin-top: 0;
    height: calc(100vh - 2.375rem - 55px);
  }
}

.sidebar-session-show {
  .main {
    margin-right: $sidebar-session-width;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-right: $sidebar-session-width-md;
    }
    //transition: margin-right .2s;
  }

  @include media-breakpoint-down(md) {
    .main {
      margin-right: 0;
    }

    .main:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1016;
      width: 100%;
      height: 100%;
      content: "";
      background: rgba(0, 0, 0, 0.7);
      transition: opacity 0.2s;
    }
  }
}
