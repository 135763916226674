.main {
  display: flex;
  flex-direction: row;
}

.main-container {
  flex: 1;
  min-width: 0;
  position: relative;
}

.main .container-fluid {
  padding: 0 15px;
}
