@import "variables";

$enable-print-styles: false;
$dropdown-item-padding-y: 3px !default;
$dropdown-item-padding-x: 20px !default;

/*$input-btn-padding-y-sm: .375rem !default;
$input-btn-padding-x-sm: .75rem !default;*/

$btn-focus-box-shadow: none !default;
$btn-active-box-shadow: none !default;
$btn-box-shadow: none !default;

$grid-gutter-width: 20px !default;

$sidebar-bg: #2c497b !default;
$sidebar-nav-dropdown-bg: #3c5d96 !default;

@import "~bootstrap/scss/mixins";
@import "~@coreui/coreui/scss/variables";
