.cards-src-carousel-container {
  padding-top: 38px;
  padding-bottom: 38px;
}

.cards-src-carousel-place {
  min-height: 196px;
  user-select: none;

  .swiper-inner {
    width: 100%;
    height: 400px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    width: 150px;
    height: 196px;
  }

  .s-card {
    width: 150px;
    height: 195px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 392px;

    .swiper-slide {
      width: 300px;
      height: 392px;
    }

    .s-card {
      width: 300px;
      height: 390px;
    }
  }

  .swiper-slide-active {
    .s-card {
      border-color: #2c497b;
    }
  }
}

.carousel-bs-nav {
  .swiper-button-next,
  .swiper-button-prev {
    width: 48px;
    height: 48px;
  }

  .swiper-button-prev {
    background: url('../img/carousel-prev.png');
    left: 0;
  }

  .swiper-button-next {
    background: url('../img/carousel-next.png');
    right: 0;
  }
}

.cards-src-carousel {
  position: relative;
  padding: 0 56px;
  margin-top: 1rem;

  .swiper-button-next,
  .swiper-button-prev {
    margin-top: -22px -25px;
  }
}

.cards-src-carousel__action-add {
  display: none;
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%);
  bottom: 10px;

  @include media-breakpoint-up(sm) {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    transform: translate(-50%, 100%);
    bottom: 0;
  }
}

.cards-src-carousel-controls {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-top: -.5rem;
  position: relative;

  & > * {
    margin-top: .5rem;
  }

  @include media-breakpoint-down(sm) {
    & > * {
      width: 100% !important;
    }
  }
}

.cards-src-carousel-control__search {
  width: 242px;
  position: relative;

  &:before {
    @include fa-icon;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    content: "\f002";
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.6rem;
    margin-top: -2px;
  }

  @include media-breakpoint-up(lg) {
    width: 292px;
  }

  .search-input {
    padding: .375rem 3rem .375rem 1rem;
  }
}

.cards-src-carousel__control-add {
  /*margin-left: auto;
  margin-right: auto;*/

  @include media-breakpoint-up(md) {
    margin-left: 50%;
    transform: translate(-50%);
    position: absolute;
  }
}

.cards-src-carousel-control__new-card {

}

.cards-src-carousel-box {
  display: flex;
  flex-direction: row;

  & > *:first-child {
    flex: 1;
  }

  & > *:nth-child(2) {
    position: relative;
    margin-left: 30px;
    display: none;
    flex-direction: column;
    justify-content: flex-end;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
}
