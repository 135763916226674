.comment-embedded {
  color: #2f2f2f;
}

.comment-embedded__content {
  position: relative;

  .comment-embedded--has-comment & {
    padding-left: 22px;
  }
}

.comment-embedded__text {
  margin: 0;
}

.comment-embedded__action-remove {
  padding: 0 !important;
  color: #2f2f2f;
  position: absolute;
  left: 3px;
  top: 0;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    color: #2f2f2f;
  }
}

.comment-embedded__action-add {
  padding: 0 !important;
  color: #c0c1c3;
  font-weight: 400;
  text-decoration: underline;
  line-height: 1.25;
  cursor: pointer;
  transition: all .2s;

  .comment-embedded--pending & {
    color: #2f2f2f;
  }

  &:hover {
    color: #2f2f2f;
  }
}
