$simple-line-font-path: "fonts/";

$font-size-root: 14px;
$font-size-base: 1rem;
$font-family-base: 'Futura Medium', sans-serif;

$body-color: #2f2f2f;

$enable-shadows:                              true !default;
$enable-validation-icons:                     false !default;

$line-height-lg:              1.25 !default;
$line-height-sm:              1.25 !default;
$line-height-base: 1.25;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1170px
) !default;

$sidebar-width:                       220px !default;

@import "colors";
