.panel-a-actions {
  display: flex;
  flex-wrap: wrap;
}

.panel-a-action {
  transition: color .2s;
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 5px;

  &:last-of-type {
    margin-right: 0;
  }

  span {
    display: flex;
    align-items: center;
  }

  .fa {
    font-size: 26px;
    color: #dde1e7;
    margin-right: .5rem;
    transition: color .2s;
  }

  &:hover {
    .fa {
      color: #2f2f2f;
    }
  }
}

.panel-a-action--yellow {
  .fa {
    color: $color-11;
  }

  &:hover {
    .fa {
      color: $color-11;
    }
  }
}

.panel-a-action--green {
  .fa {
    color: $color-6;
  }

  &:hover {
    .fa {
      color: $color-6;
    }
  }
}
