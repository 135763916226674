.m-card-container {
  display: flex;
  height: 100%;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
}

.m-card-header {
  margin-bottom: 1rem;
  font-weight: bold;
}

.m-card-wrap {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  justify-content: center;

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 470px));
  }
}

.m-card {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #aaaaaa;
  overflow: hidden;
}

.m-card__pic {
  max-width: 100%;
  height: auto;
}
