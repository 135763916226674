%clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

.clearfix {
  @extend %clearfix;
}

.pull-right {
  float: right;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}


.link-color-b-blue {
  color: #2c497b;
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &:hover, &:active {
    color: #2c497b;
    text-decoration: none;
    border-bottom-color: currentColor;
  }
}

.bg-1 {
  background-color: $color-1;
}

.bg-2 {
  background-color: $color-2;
}

.bg-3 {
  background-color: $color-3;
}

.bg-4 {
  background-color: $color-4;
}

.bg-5 {
  background-color: $color-5;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.required:after {
  content:" *";
  color: red;
}
