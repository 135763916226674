.m-card-figures {
  display: grid;
  grid-template-columns: repeat(auto-fit, 128px);
  grid-gap: 1rem;
  justify-content: center;
  max-height: 128px * 2 + 16px;
  overflow: auto;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(auto-fit, 138px);
    max-height: 138px * 2 + 16px;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(auto-fit, 170px);
    max-height: 170px * 2 + 16px;
  }
}

.m-card-figure {
  position: relative;
  padding-bottom: 100%;
  background: no-repeat center center;
  background-size: contain;
  cursor: pointer;
}
