.folder-grid-view {
  padding: 8px 3px;
}

.folder-grid-view.scrollable {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.folder-grid-view__title {
  font-size: .9em;
  color: #bbb;
  /*text-transform: uppercase;*/
  margin-bottom: 10px;
  margin-top: 0;
  margin-left: 12px;
}

.folder-grid-item {
  position: relative;
  float: left;
  width: 240px;
  margin: 0 10px 20px;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #cbcbcb;
  box-shadow: 0 0 1px 0 #cbcbcb;
  background: #fff;
}

.folder-grid-item__list {
  display: flex;
  flex-wrap: wrap;
}

.folder-grid-item__list--session {

}

.folder-grid-item--folder .folder-grid-item__content {
  display: flex;
  align-items: center;
}

.folder-grid-item__thumb-preview {
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  height: 100%;
  background: center center no-repeat;
  background-size: cover;
  border-radius: 4px 4px 0 0;
  position: relative;

  &.has-cover {
    border-bottom: 1px solid #d6dadc;
  }
}

.folder-grid-item__thumb-preview--icon {
  font-size: 42px;
}

.folder-grid-item__thumb-container {
  flex: 0 0 145px;
  position: relative;
}

.folder-grid-item__info {
  flex: 1 1 auto;
  display: flex;
  padding: 8px 0 8px 12px;
  flex-direction: column;
}

.folder-grid-item__info-name {
  display: flex;
  align-items: center;
}

.folder-grid-item__thumb-preview-img {
  max-width: 238px;
  max-height: 145px;
}

.folder-grid-item__icon {
  flex: 0 0 auto;
  height: 24px;
  padding: 12px 16px;
  position: relative;
  width: 24px;
  box-sizing: content-box;
  text-align: center;
  line-height: 24px;
  font-size: 20px;
}

.folder-grid-item__name {
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

.folder-grid-item__actions {
}

.folder-grid-item__action {
}

.folder-grid-item--folder {
}

.folder-grid-item--session {
  max-height: 240px;

  .folder-grid-item__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.folder-grid-item__collaborators {
  min-height: 30px;
  display: flex;
  align-items: center;
}

.folder-grid-item__collaborator {
  position: relative;
  flex-shrink: 1;
  flex-basis: 20px;
  min-height: 20px;
}

.folder-grid-item__collaborator-avatar {
  display: block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  line-height: 18px;
  background: #fff;
  text-align: center;
}

.folder-item-overlap {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  color: #4a4a4a;
  background-color: rgba(#fff, .75);
  border-radius: 4px;
  z-index: 10;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  user-select: none;
  overflow: hidden;
}

.folder-item-overlap__icon {
  font-size: 3rem;
  margin-bottom: .5rem;
}

.folder-item-overlap__message {
  display: block;
  padding: 0 .5rem;
  font-size: 1.2rem;
}

.folder-item-overlap__name {
  display: block;
  margin-bottom: 1rem;
  padding: 0 .5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.folder-list-item--session {
  .folder-item-overlap--list {
    background-color: rgba(#f4f4f4, .75);
  }

  &:nth-child(2n) {
    .folder-item-overlap--list {
      background-color: rgba(#fff, .75);
    }
  }
}

.folder-item-overlap--list {
  border-radius: 0;

  &.folder-item-overlap {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .folder-item-overlap__icon {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .folder-item-overlap__message {
    font-size: 1rem;
  }

  .folder-item-overlap__name {
    margin-bottom: 0;
  }
}

.folder-grid-item__thumb-icons {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 14px;
}

.folder-grid-item__thumb-icon {
  margin-top: 3px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #e9ecef;

  &:first-child {
    margin-top: 0;
  }
}
