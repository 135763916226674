.steps-progress {
  margin: 20px 0;
  position: relative;
  background-color: #f1f6fa;
  width: 100%;
  height: 14px;
  border-radius: 7px;
  overflow: hidden;
}

.steps-progress__progress {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  background-color: #6ed232;
  transition: width .2s;
}
