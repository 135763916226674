.form {

}

.form-item__error {
  color: #F56C6C;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

.form-item {
  margin-bottom: 22px;
}

.col-form-label, .form-item__label {
  display: block;
  vertical-align: middle;
  font-weight: bold;
}
