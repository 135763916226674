$iw-card-width: 130px;
$iw-card-height: $iw-card-width * 1.3;
$iw-card-height-time: 18px;
$iw-card-height-with-time: $iw-card-height + $iw-card-height-time;
$iw-grid-gap: 16px;

.iw-container {
  background-color: #fff;
  padding: 0 !important;
}

.iw-grid-container {
  & > div {
    padding: 1rem;
    display: grid;
    overflow-y: auto;
  }
}

.iw-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.iw-item-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($iw-card-width + $iw-grid-gap, max-content));
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
}

.iw-grid-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: .5rem;
}

.iw-item-container--request {
  background-color: rgba(#ed1b24, .1);
}

.iw-item-container--result {
  background-color: rgba(#0072bb, .1);
}

.iw-item-container--module-group {
  background-color: rgba(#0cb14b, .1);

  & > .iw-item-container > .iw-item-group {
    background-color: #dedeef;
  }

  & > .iw-item-container > .iw-item-group:nth-child(2n) {
    background-color: #fff2d8;
  }

  & > .iw-item-container > .iw-item-group:last-child {
    background-color: transparent;
  }
}

.iw-item-group {
  position: relative;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}

.iw-item-group--no-nested {
  display: block;
}

.iw-item-container--track {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(#95a4ab, .2);
  padding-top: 22px;
  position: relative;
}

.track-block-time-wrap {
  position: absolute;
  left: 8px;
  top: 4px;
}

/*.iw-item-group--track {
  display: block;
}*/

.iw-item-sub--track {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iw-item-sub[class*="level"]:not(.iw-item-container--track) {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  /*  .iw-item-group {
      display: block;
    }*/
}

.iw-item-sub {
  min-height: 30px;
  justify-items: center;
  min-width: $iw-card-width;
  grid-template-columns: repeat(auto-fit, minmax($iw-card-width, max-content));
}

.iw-item-wrap {
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .iw-item-group > & {
    margin: 8px 0 0;
  }

  .iw-item-group--track > & {
    margin: 0 8px;
  }
}

.iw-item--ms {
  padding-bottom: 8px;
}

.iw-item-container--request,
.iw-item-container--result {
  .iw-item-group--no-nested > .iw-item-wrap,
  .iw-item-group.filtered > .iw-item-wrap {
    padding-bottom: 0;
  }
}

.iw-item-main {
  width: $iw-card-width;
}

.iw-item--dummy {
  .iw-card__actions {
    display: none;
  }
}

.iw-item {
  display: flex;
  flex-wrap: wrap;
}

.iw-card {
  position: relative;
  width: $iw-card-width;
  height: $iw-card-height;
  //box-shadow: 1px 1px 2px rgba(0, 0, 0, .15);
  background: #fff no-repeat center center;
  background-size: cover;
  border-radius: 5px;
  border: 1px solid darkgrey;
}

.iw-card__actions {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 4px;
  right: 4px;
}

.iw-card__action {
  margin-top: 5px;
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer !important;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  color: #dde1e7;
  /*color: #8d8d8d;*/
  background-color: #94b4e7;
  transition: color .2s, opacity .2s;
  opacity: .75;
  font-size: 13px;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    opacity: 1;
    color: #2f2f2f;
  }

  .btn {
    padding: 0;
    width: 100%;
    color: inherit;
    line-height: 1;
  }

  .dropdown-menu {
    min-width: auto;
  }

  .dropdown-item i {
    margin: 0;
  }
}

.iw-card--action-remove {
  &:before {
    @include fa-icon;
    content: "\f00d";
  }
}

.iw-card--track {
  display: flex;
  justify-content: center;
  align-content: center;
  background: #fff;

  & > .iw-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > .iw-item-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.iw-card--add {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;

  &:before {
    @include fa-icon;
    font-family: $font-family-base;
    position: absolute;
    content: "+";
    font-size: 34px;
  }
}

.iw-card__pic {
  display: block;
  max-width: 100%;
  height: auto;
}

.iw-grid-container {
  .ghost-grid-card {
    position: relative;
    height: $iw-card-height;
    width: $iw-card-width;
    margin-top: 8px;
    overflow: hidden;

    &:after {
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      content: "";
      background-color: #c3c3c3;
    }
  }

  .filtered {
    //cursor: default;
  }

  .drag-item {
    cursor: grab;
  }

  .sortable-drag {
    overflow: hidden;

    &:after {
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      content: "";
      background-color: #3c5d96;
    }
  }
}

.iw-item-container--request,
.iw-item-container--result {
  .ghost-grid-card {
    margin-top: 0;
    margin-left: 8px;
  }
}

.iw-item-container.iw-item-container--show-time {
  .ghost-grid-card {
    height: $iw-card-height-with-time;
  }
}

.iw-item-container--module-group > .iw-item-container > .iw-item-group {
  .ghost-grid-card {
    margin: 8px 0 0;
  }

  &.ghost-grid-card {
    margin: 8px 8px 0;
  }
}

.iw-grid--horizontal {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;

  .ghost-grid-card {
    min-width: 130px;
  }

  .iw-item-container--request & .ghost-grid-card,
  .iw-item-container--result & .ghost-grid-card {
    margin-top: 8px;
    margin-left: 4px;
    margin-right: 4px;
  }

  &.iw-item-container {
    display: flex;
    justify-content: safe center;
    flex-wrap: nowrap;
    user-select: none;
    white-space: nowrap;
    margin-bottom: 8px;
    padding-bottom: 8px;
    position: relative;
    right: 0;
  }

  .iw-item-group > .iw-item-wrap {
    margin: 8px 4px;
  }

  & > .iw-item-group > .iw-item-wrap {
    margin: 8px 4px 0;
  }

  .iw-item-sub > .iw-item-group > .iw-item-wrap {
    margin-bottom: 0;
  }

  .iw-item-container--track {
    flex-wrap: nowrap;
  }
}
