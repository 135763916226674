@import "../partials/utils";

.card-header-preview {
  font-size: 28px;
  font-weight: bold;
}

.card-header-preview__body {
  display: inline-block;
  padding: 8px 22px;
}

// -------------------------------------------------------------

.card-figure-preview {
  position: relative;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  width: 148px;
  height: 148px;
}

.card-figure-preview__thumbnail {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-figure-preview--text {
  cursor: pointer;
  text-align: center;

  &:hover {
    border-color: #409EFF;
  }
}

.card-figure-preview__icon-plus {
  line-height: 148px;
}

.card-figure-preview__actions {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s;

  &::after {
    display: inline-block;
    content: "";
    height: 100%;
    vertical-align: middle;
  }

  & > span {
    display: none;
    cursor: pointer;
  }

  &:hover {
    opacity: 1;

    & > span {
      display: inline-block;
    }
  }
}

// -------------------------------------------------------------

.card-preview {
  position: relative;
  margin: 22px 0;

  @include clearfix;
}

.card-preview__front, .card-preview__back {
  position: relative;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  width: 148px;
  height: 148px * 1.3;
  margin-right: 22px;
  float: left;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
