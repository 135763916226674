.cards-dest-carousel-container {
  //padding: 38px 0;

  .swiper-button-next,
  .swiper-button-prev {
    position: static;
    top: auto;
    margin-top: auto;
  }
}

.cards-dest-carousel-place-wrap {
  position: relative;
}

.cards-dest-carousel-place-wrap.dest-success {
  .cards-dest-carousel-zone {
    color: #28a745;

    &:after {
      opacity: 1;
      transition: opacity .2s;
    }
  }
}

.cards-dest-carousel-place-wrap--drag-receiver {
/*  .cards-dest-carousel-zone {
    display: block;
  }*/
}

.cards-dest-carousel-zone {
  background: rgba(#fff, .9);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  color: #2c497b;

  &:before {
    content: "";
    display: block;
    position: absolute;
    border: 2px dashed currentColor;
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
    transition: border .2s;
  }

  &:after {
    display: block;
    content: "\f067";
    @include fa-icon;
    position: absolute;
    font-size: 64px;
    left: 50%;
    top: 50%;
    opacity: 0;
    margin-top: -32px;
    margin-left: -32px;
    transition: color .2s;
  }
}

.cards-dest-carousel-place {
  min-height: 196px;
  user-select: none;

  .swiper-inner {
    width: 100%;
    height: 400px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    width: 150px;
  }

  .s-card {
    width: 150px;
    height: 195px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 342px;

    .swiper-slide {
      width: 262px;
      height: auto;
    }

    .s-card {
      width: 262px;
      height: 340px;
    }
  }
}

.cards-dest-carousel {
  position: relative;
}

.cards-dest-carousel__header {
  display: flex;
  justify-content: center;
  font-size: 24px;
  align-items: center;
  margin-bottom: 32px;
}

.cards-dest-carousel__header {
  .swiper-button-prev,
  .swiper-button-next {
    width: 32px;
    height: 32px;
  }

  .swiper-button-prev {
    background: url('../img/carousel-s-prev.png');
  }

  .swiper-button-next {
    background: url('../img/carousel-s-next.png');
  }
}

.cards-dest-carousel__header-place {
  margin: 0 20px;
}


