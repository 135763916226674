@font-face {
  font-family: "Futura_Medium";
  src: url("../fonts/Futura_Medium.woff") format("woff");
  src: url("../fonts/Futura_Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fontello";
  src: url("../fonts/fontello.woff") format("woff");
  src: url("../fonts/fontello.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
