.session-steps-container {
  /*padding: 20px 15px;*/
  min-height: 100%;
  background-color: #fff;
  color: #2f2f2f;
}

.session-step {
  padding-bottom: 40px;
}

.session-steps-h-s {
  padding-right: 15px;
  padding-left: 15px;
}

.session-step__header-wrap {
  padding-top: 38px;
}

.session-step__header {
  text-align: center;
  font-size: rem-calc(24px);
}

.session-step-2 {
  .s-row-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .s-col:first-child {
      flex-grow: 1;
    }

    .s-col:last-child {
      width: 330px;
    }
  }
}

.session-step__row-daf {
  position: relative;
  overflow: hidden;
}

.session-step__row-daf-place {
  display: flex;
  flex-wrap: nowrap;
}

.session-step__row-daf-place-a {
  min-width: 0;
  flex-grow: 1;
}

.session-step__row-daf-place-b {
  width: 250px;
  position: relative;
  flex: none;
  margin-left: auto;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 9999px;
    bottom: 0;
    background-color: #c5dafb;
  }
}

.session-step__row-comment-block {
  margin-top: 20px;
  margin-bottom: 24px;
}

.session-step__cards-dest {
  padding: 38px 0 38px 0;

  @include media-breakpoint-up(lg) {
    padding-right: 30px;
  }
}

.session-step__favorite {
  padding: 38px 0 38px 15px;
  position: relative;
}

.session-step__favorite-header {
  margin-bottom: 32px;
}

.session-step__favorite-header-place {
  display: inline-block;
}

.session-step-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    margin-top: .5rem;
  }

  .btn-bs {
    min-width: 190px;
  }
}

.session-step-nav__prev {
  margin-right: 16px;
}

.session-step-nav__board {
  margin-left: auto;
  cursor: pointer;
  font-size: rem-calc(18px);
}

.step-header-2 {
  font-size: 24px;
  font-weight: 600;
}

.session-step__header {

}

.session-step__sym-favorite {
  color: $color-11;
  font-size: 26px;
  display: inline-block;
  margin-right: 10px;
}

.session-step {
  .mce-content-body {
    p {
      margin: 0 0 4px;
    }
  }
}
