.sort-option-group {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 1.5;
  color: #bbb;
}

.sort-option-item {
  padding: 1px 5px;
  margin-left: 2px;
  margin-right: 2px;

  &:not(.disabled).active,
  &:not(.disabled):active,
  &:not(.disabled):hover {
    color: #414141;
    cursor: pointer;
  }
}

.sort-option-group {
  .sort-option-item {
    margin-left: 0;
    margin-right: 0;
  }

  &.list {
    & > *:first-child {
      padding-left: 0;
    }

    & > *:last-child {
      padding-right: 0;
    }

    .name {
      min-width: 120px;
      flex: 1 1 auto;
    }

    .client {
      min-width: 100px;
      flex: 0 0 100px;
    }

    .start_date {
      /*text-align: right;*/
    }

    .collaborators, .start_date {
      width: 100px;
      flex: 0 0 100px;
      min-width: 100px;
    }

    .updated_at {
      text-align: right;
      width: 115px;
      flex: 0 0 115px;
      padding-right: 0;
      min-width: 60px;
    }
  }
}
