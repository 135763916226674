.s-card {
  position: relative;
  width: 300px;
  height: 390px;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #aaaaaa;
  transition: border-color .2s;
  overflow: hidden;
}

.s-card__pic {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.s-card__drag {
  display: none;
  position: absolute;
  color: #dde1e7;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  right: 4px;
  top: 32px;
  background-color: #94b4e7;
  transition: color .2s;

  &:before {
    @include fa-icon;
    content: "\f255";
  }

  .swiper-slide-active & {
    cursor: grab;
  }

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.s-card__panel {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  bottom: 4px;
}

.s-card__panel--item {
  display: block;
  color: #dde1e7;
  width: 24px;
  height: 24px;
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  object-fit: contain;
  margin-top: 4px;
  transition: color .2s;

  &.s-card__panel--item-active {
    color: $color-6;
  }

  .swiper-slide-active &:not(.no-action) {
    cursor: pointer;
  }

  .swiper-slide-active &:not(.s-card__panel--item-active) {
    &:hover {
      color: #2f2f2f;
    }
  }

  &:first-child {
    margin-top: 0;
  }
}

.s-card__favorite {
  &:before {
    @include fa-icon;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    content: "\f005";
  }

  &.s-card__panel--item-active {
    color: $color-11;
  }
}

.s-card__edit {
  &:before {
    @include fa-icon;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    content: "\f040";
  }
}

.s-card__team {
  &:before {
    @include fa-icon;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    content: "\F0C0";
  }
}

.s-card__actions {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition: opacity .2s;
  background: rgba(#000, .5);
  justify-items: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 32px;
  cursor: pointer;

  &:hover, &:active {
    opacity: 1;
  }
}

.s-card {
  &.s-card--empty {
    border: 2px solid transparent;
    cursor: pointer;
  }

  .s-card__content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12px;
    top: 12px;
    right: 12px;
    bottom: 12px;
    border-radius: 10px;
    color: #c0c1c3;
    font-size: 18px;
    font-weight: 400;
    border: 2px solid #dde1e7;
    text-align: center;
  }
}

.s-card.src-success {
  &:before {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(#28a745, .6);
  }
}

.slide-s-card {

}

.slide-s-card__comment {
  margin-top: .75rem;
}

.slide-s-card__duration {
  margin-top: .5rem;
  font-size: rem-calc(20px);
  text-align: center;

  & + .slide-s-card__comment {
    margin-top: .5rem;
  }
}

.s-card__close {
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  right: 4px;
  top: 4px;
  color: #dde1e7;
  background-color: #94b4e7;
  transition: color .2s;

  &:hover, &:active {
    color: #2f2f2f;
  }

  &:before {
    @include fa-icon;
    content: "\f00d";
  }
}
