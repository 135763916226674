.t-color .nav-link{
    color:#2c497b;
}
.t-color .nav-link.active{
    opactity: 0.5 !important;
    background-color: #2c497b;
    color:#fff;
}
.t-color .card.border-primary{
    border-color: #2c497b !important;
}
.t-color .tab-pane .accordion-name:focus {
    background-color: #20c997;
}
.t-color .tab-pane .accordion-name{
    text-align: left;
    border-color: unset;
    background-color: #20c997;
    color:#fff;
}
.t-color .tab-pane .accordion-block,.t-color .tab-pane .accordion-block:focus{
    border:unset;
}
.header-tariff_name, .header-tariff_price{
    padding: 0.75rem 1.25rem;
    color: #fff;
    background-color: #2c497b;
    font-size: 18px;
}
.header-tariff_price{
    font-size: 16px;
    opacity: .7;
}
.tariff-cards .card-header{
    padding: 0;
}
.tariff-cards .header-tariff_name, .tariff-cards .header-tariff_price, .tariff-cards p{
    text-align: center;
}
