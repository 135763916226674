#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #000;
  backface-visibility: hidden;
}

#preloader > div {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e85656;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation: spin 2s linear infinite;
}

#preloader > div::after,
#preloader > div::before {
  content: "";
  position: absolute;
}

#preloader > div::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #209e91;
  animation: spin 3s linear infinite;
}

#preloader > div::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #dfb81c;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}