.single-image-uploader-wrap {
  width: 148px;
  height: 148px;
}

.single-image-uploader, .siu {
  .el-upload {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    width: 148px;
    height: 148px;
    cursor: pointer;
  }

  .el-upload:hover {
    border-color: #409EFF;
  }
}

.siu__icon-plus {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 148px;
  text-align: center;
}

.siu__picture {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.siu__picture-thumbnail {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.siu__picture-actions {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s;

  &::after {
    display: inline-block;
    content: "";
    height: 100%;
    vertical-align: middle;
  }

  & > span {
    display: none;
    cursor: pointer;
  }

  &:hover {
    opacity: 1;

    & > span {
      display: inline-block;
    }
  }
}

.uploader-card-container {
  @extend %clearfix;

  /*.single-image-uploader-wrap {
    float: left;
    margin-right: 22px;
  }*/

  .single-image-uploader-wrap,
  .single-image-uploader,
  .siu,
  .siu .el-upload {
    width: 148px;
    height: 148px * 1.3;
  }

  .siu__icon-plus {
    line-height: 148px * 1.3;
  }
}
