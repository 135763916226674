.day-tabs {
  display: flex;
  white-space: nowrap;
  min-height: 25px;
  border-bottom: 1px solid #bbb;
}

.day-tab {
  position: relative;
  display: block;
  font-size: .85rem;
  color: #777;
  white-space: nowrap;

  a {
    position: relative;
    display: block;
    cursor: pointer;
    color: inherit;
    padding: 4px 12px 0;
    height: 24px;
    background: none;
  }

  &.active {
    font-weight: 700;
    color: #555;
    border-bottom: 2px solid #5cb85c;
  }

  &:hover:not(.active) {
    color: #555;
    border-bottom: 1px solid #5cb85c;
  }
}
