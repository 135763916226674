@charset "UTF-8";
/*$input-btn-padding-y-sm: .375rem !default;
$input-btn-padding-x-sm: .75rem !default;*/
.block {
  position: relative;
  background-color: #fff;
  min-height: 85px;
  margin: 5px 20px 10px;
}

.block__header > .block-actions {
  padding: 3px;
  margin-left: auto;
}

.block__header > .block-actions > .btn-group, .block__header > .block-actions > .icon-btn {
  min-width: 19px;
}

.block__header > .block-actions .icon-btn, .block__header > .block-actions .btn {
  padding: .25rem 0;
  margin: 0 3px;
  font-size: small;
  color: #8d8d8d;
}

.block__header > .block-actions .icon-btn .icon-btn, .block__header > .block-actions .icon-btn .btn, .block__header > .block-actions .btn .icon-btn, .block__header > .block-actions .btn .btn {
  padding: 0;
  margin: 0;
}

.block__header > .block-actions .icon-btn.active, .block__header > .block-actions .icon-btn:hover:focus, .block__header > .block-actions .btn.active, .block__header > .block-actions .btn:hover:focus {
  color: #414141;
  text-decoration: none;
}

.block__header > .block-actions .icon-btn:active:focus, .block__header > .block-actions .icon-btn.active:focus, .block__header > .block-actions .btn:active:focus, .block__header > .block-actions .btn.active:focus {
  box-shadow: none;
}

.block--has-time-gap {
  margin-top: 25px !important;
}

.block__time-overlap {
  font-size: .85rem;
  color: red;
  margin-left: 6px;
}

.block__header {
  display: flex;
  align-items: center;
  /*justify-content: space-between;*/
  padding: 4px 0;
  flex-wrap: wrap;
  cursor: grab;
  border-bottom: 1px solid #cbcbcb;
}

.block__header .handle {
  padding: 0 6px 0 8px;
}

.block__time-gap {
  position: absolute;
  top: -24px;
  width: 85px;
  padding-bottom: 6px;
  text-align: center;
  font-style: italic;
}

.block__time-wrap {
  cursor: default;
  padding-left: 6px;
}

.block--disabled .block__time,
.block--disabled .block__header,
.block--disabled .block__v-del {
  cursor: default;
}

.block__time {
  display: inline-block;
  cursor: pointer;
}

.block__duration {
  text-align: center;
  font-style: italic;
  font-weight: 400;
  padding-right: 6px;
  display: flex;
  align-items: center;
}

.block__name {
  font-size: .85rem;
  font-weight: bold;
  overflow: hidden;
  padding: 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1 auto;
  display: flex;
  cursor: default;
  align-items: center;
}

.block__content-extra .block__desc {
  min-width: 60%;
}

.block__content-extra {
  background-color: #f4f4f4;
  min-width: 168px;
  margin-right: -1px;
  margin-bottom: -1px;
  display: flex;
  flex-direction: column;
}

.block__content-extra label {
  display: block;
  text-align: center;
}

.block__content-extra > div {
  border-bottom: 1px solid #cbcbcb;
  padding: 5px 10px;
  background-color: #fff;
  position: relative;
  height: auto;
  min-height: 59px;
  font-size: small;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #cbcbcb;
  /*margin-right: -1px;*/
  flex: 1 1 25%;
}

@media (min-width: 576px) {
  .block__content-extra {
    flex-direction: row;
    /*& > div {
      min-width: 330px;
    }*/
  }
}

/* -------------------------------------------------------------- */
.block--method {
  border: 1px solid #cbcbcb;
}

.block--method-mini {
  font-size: 14px;
  min-height: 24px;
  height: auto !important;
  border: none;
  margin: 0 10px;
  background-color: transparent;
}

.block--method-mini .editable-input {
  font-size: 14px;
}

.block--method-mini .block__duration {
  background-color: #fff;
  min-width: 22px;
}

.block--method-mini .block__name {
  background-color: #fff;
  padding: 0 6px;
}

.block--method-mini.block--has-time-gap {
  margin-top: 20px !important;
}

.block--method-mini .block__time-gap {
  top: -18px;
  text-align: right;
  font-style: italic;
  padding-bottom: 0;
  right: 2px;
}

.block--method-mini .block__time-wrap {
  height: 24px;
  width: 46px;
  background-color: #fff;
  text-align: center;
  line-height: 24px;
  padding-left: 0;
}

/* -------------------------------------------------------------- */
.block--module {
  background: #d1def1;
  height: auto;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  border: none;
}

.block--module {
  padding-bottom: 10px;
}

.block--module-mini, .block--tracks-mini {
  padding-bottom: 5px;
  font-size: 14px;
  min-height: 53px;
}

.block--module-mini .editable-input, .block--tracks-mini .editable-input {
  font-size: 14px;
}

.block--module-mini .block__name, .block--tracks-mini .block__name {
  padding: 0 6px;
}

.block--module-mini > div > .block__time-wrap, .block--tracks-mini > div > .block__time-wrap {
  height: 24px;
  width: 46px;
  text-align: center;
  line-height: 24px;
  padding-left: 0;
  margin-left: 10px;
}

.block--module-mini > div > .block__v-del, .block--tracks-mini > div > .block__v-del {
  width: 18px;
}

/* -------------------------------------------------------------- */
/* -------------------------------------------------------------- */
.block--tracks {
  background: #e2e2e2;
  margin-left: 0;
  margin-right: 0;
}

/* -------------------------------------------------------------- */
.block--tracks {
  background: #e2e2e2;
}

.block-track {
  padding-bottom: 10px;
}

.block-track .block {
  margin-right: 10px;
  margin-left: 10px;
}

.block--track-mini .block-track__blocks {
  padding-left: 0;
  padding-right: 0;
  margin-top: 5px;
  min-height: 24px;
}

.block-track__blocks {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  min-height: 50px;
}

/* -------------------------------------------------------------- */
.block--dummy:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #4dc0b5;
}

.day {
  background: #f4f4f4;
  margin: 1em 0;
}

.day-tabs {
  display: flex;
  white-space: nowrap;
  min-height: 25px;
  border-bottom: 1px solid #bbb;
}

.day-tab {
  position: relative;
  display: block;
  font-size: .85rem;
  color: #777;
  white-space: nowrap;
}

.day-tab a {
  position: relative;
  display: block;
  cursor: pointer;
  color: inherit;
  padding: 4px 12px 0;
  height: 24px;
  background: none;
}

.day-tab.active {
  font-weight: 700;
  color: #555;
  border-bottom: 2px solid #5cb85c;
}

.day-tab:hover:not(.active) {
  color: #555;
  border-bottom: 1px solid #5cb85c;
}

.session-editor {
  background: #f4f4f4;
  padding: 1em;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.session-total-time {
  display: flex;
}

.session-total-time__icon {
  display: none;
  color: red;
  padding-right: 6px;
  align-items: center;
}

.session-total-time--overlap .session-total-time__icon {
  display: block;
}

.timepicker {
  width: 160px;
  /*.btn {
    display: block;
  }*/
}

.timepicker table {
  margin: 0 auto;
}

.session-editor__header .col-form-label {
  padding-top: 0;
  padding-bottom: 5px;
}

.session-editor__row-header {
  display: grid;
}

.session-editor__header-row-extra-columns .col-md-4 {
  min-width: 280px;
}

.track-tabs {
  display: flex;
  white-space: nowrap;
  min-height: 25px;
  border-bottom: 1px solid #bbb;
}

.track-tab {
  position: relative;
  display: block;
  font-size: 12px;
  min-height: 24px;
  color: #777;
  white-space: nowrap;
}

.track-tab a {
  position: relative;
  display: block;
  cursor: pointer;
  color: inherit;
  padding: 4px 12px 0;
  height: 24px;
  background: none;
}

.track-tab.active {
  font-weight: 700;
  color: #555;
  border-bottom: 2px solid #5cb85c;
}

.track-tab:hover:not(.active) {
  color: #555;
  border-bottom: 1px solid #5cb85c;
}

.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
}

.session-card-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
}

.session-card__actions {
  display: flex;
  justify-content: center;
}

.session-card__action {
  cursor: pointer;
  font-size: 2em;
}

.session-card {
  position: relative;
  transform-style: preserve-3d;
  perspective: 800px;
  transition: 1s;
  width: 308px;
  height: 400px;
  border-radius: 10px;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid #dfdfdf;
}

.session-card__front, .session-card__back {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 10px;
}

.session-card__front img, .session-card__back img {
  border-radius: 10px;
}

.session-card__back {
  transform: rotateY(180deg);
}

.session-card__pic {
  object-fit: contain;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.session-card--flip {
  transform: rotateY(180deg);
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #000;
  backface-visibility: hidden;
}

#preloader > div {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e85656;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation: spin 2s linear infinite;
}

#preloader > div::after,
#preloader > div::before {
  content: "";
  position: absolute;
}

#preloader > div::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #209e91;
  animation: spin 3s linear infinite;
}

#preloader > div::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #dfb81c;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sidebar-closed .sidebar-panel {
  left: -15rem;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 10001;
  width: 15rem;
  background-color: rgba(30, 33, 41, 0.96);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: hidden;
}

.sidebar-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.sidebar-backdrop.visible {
  visibility: visible;
  opacity: 1;
}

.view-table-wrap {
  height: 100%;
  width: 100%;
}

.view-table {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.folder-toolbar {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.folder-toolbar-header, .fth {
  display: flex;
  padding: 10px 15px 0;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
}

.fth-row {
  padding: 0 10px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fth-row__content {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  align-items: center;
}

.fth-row__column {
  flex-grow: 1;
  padding: 5px;
  width: 120px;
}

.fth-row__actions .btn-sm {
  padding: 5px;
}

.fth__breadcrumb {
  flex: 3 1 155px;
}

.fth__breadcrumb .breadcrumb {
  padding: 6px 12px 6px 0;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: none;
}

.fth__actions {
  display: flex;
}

.view-row {
  display: table-row;
}

.view-row.scrollable {
  height: 100%;
}

.view-wrapper {
  position: relative;
  height: 100%;
}

.view-cell {
  display: table-cell;
}

.folder-grid-view {
  padding: 8px 3px;
}

.folder-grid-view.scrollable {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.folder-grid-view__title {
  font-size: .9em;
  color: #bbb;
  /*text-transform: uppercase;*/
  margin-bottom: 10px;
  margin-top: 0;
  margin-left: 12px;
}

.folder-grid-item {
  position: relative;
  float: left;
  width: 240px;
  margin: 0 10px 20px;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #cbcbcb;
  box-shadow: 0 0 1px 0 #cbcbcb;
  background: #fff;
}

.folder-grid-item__list {
  display: flex;
  flex-wrap: wrap;
}

.folder-grid-item--folder .folder-grid-item__content {
  display: flex;
  align-items: center;
}

.folder-grid-item__thumb-preview {
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  height: 100%;
  background: center center no-repeat;
  background-size: cover;
  border-radius: 4px 4px 0 0;
  position: relative;
}

.folder-grid-item__thumb-preview.has-cover {
  border-bottom: 1px solid #d6dadc;
}

.folder-grid-item__thumb-preview--icon {
  font-size: 42px;
}

.folder-grid-item__thumb-container {
  flex: 0 0 145px;
  position: relative;
}

.folder-grid-item__info {
  flex: 1 1 auto;
  display: flex;
  padding: 8px 0 8px 12px;
  flex-direction: column;
}

.folder-grid-item__info-name {
  display: flex;
  align-items: center;
}

.folder-grid-item__thumb-preview-img {
  max-width: 238px;
  max-height: 145px;
}

.folder-grid-item__icon {
  flex: 0 0 auto;
  height: 24px;
  padding: 12px 16px;
  position: relative;
  width: 24px;
  box-sizing: content-box;
  text-align: center;
  line-height: 24px;
  font-size: 20px;
}

.folder-grid-item__name {
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

.folder-grid-item--session {
  max-height: 240px;
}

.folder-grid-item--session .folder-grid-item__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.folder-grid-item__collaborators {
  min-height: 30px;
  display: flex;
  align-items: center;
}

.folder-grid-item__collaborator {
  position: relative;
  flex-shrink: 1;
  flex-basis: 20px;
  min-height: 20px;
}

.folder-grid-item__collaborator-avatar {
  display: block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  line-height: 18px;
  background: #fff;
  text-align: center;
}

.folder-item-overlap {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  color: #4a4a4a;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  z-index: 10;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  user-select: none;
  overflow: hidden;
}

.folder-item-overlap__icon {
  font-size: 3rem;
  margin-bottom: .5rem;
}

.folder-item-overlap__message {
  display: block;
  padding: 0 .5rem;
  font-size: 1.2rem;
}

.folder-item-overlap__name {
  display: block;
  margin-bottom: 1rem;
  padding: 0 .5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.folder-list-item--session .folder-item-overlap--list {
  background-color: rgba(244, 244, 244, 0.75);
}

.folder-list-item--session:nth-child(2n) .folder-item-overlap--list {
  background-color: rgba(255, 255, 255, 0.75);
}

.folder-item-overlap--list {
  border-radius: 0;
}

.folder-item-overlap--list.folder-item-overlap {
  flex-direction: row;
  flex-wrap: wrap;
}

.folder-item-overlap--list .folder-item-overlap__icon {
  font-size: 1rem;
  margin-bottom: 0;
}

.folder-item-overlap--list .folder-item-overlap__message {
  font-size: 1rem;
}

.folder-item-overlap--list .folder-item-overlap__name {
  margin-bottom: 0;
}

.folder-grid-item__thumb-icons {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 14px;
}

.folder-grid-item__thumb-icon {
  margin-top: 3px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #e9ecef;
}

.folder-grid-item__thumb-icon:first-child {
  margin-top: 0;
}

.folder-color {
  color: #8f8f8f;
}

.folder-list-item {
  background: #fff;
  position: relative;
}

.folder-list-item:nth-child(odd) {
  background: #f4f4f4;
}

.folder-list-item, .fli {
  padding: 0 10px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.folder-list-item:hover, .fli:hover {
  background: #d5dbe5;
  /*color: #fff;*/
}

.folder-list-item--folder.last {
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
}

.fli__content {
  padding: 0;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: space-between;
}

.fli__column {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  padding: 5px;
  margin: 0;
  align-items: center;
  text-decoration: none;
}

.fli__column.name i {
  margin-right: 5px;
}

.fli__column.name {
  min-width: 120px;
  flex: 1 1 auto;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.folder-list-item--session .fli__column.name {
  flex-basis: 100%;
}

@media (min-width: 768px) {
  .folder-list-item--session .fli__column.name {
    flex: 1 1 auto;
  }
}

.fli__column.actions {
  flex: 0 0 60px;
  width: 60px;
  text-align: center;
  padding: 0;
  justify-content: end;
}

.fli__column.actions .btn {
  padding: 5px;
}

.folder-list-item--session .start_date {
  width: 100px;
  flex: 0 1 100px;
  min-width: 100px;
}

.folder-list-item--session .client {
  min-width: 100px;
  flex: 0 0 100px;
}

.folder-list-item--session .start_date, .folder-list-item--session .collaborators {
  width: 100px;
  flex: 0 0 100px;
  min-width: 100px;
}

.folder-list-item--session .updated_at {
  text-align: right;
  width: 115px;
  flex: 0 0 115px;
  padding-right: 0;
  min-width: 60px;
  /*@include media-breakpoint-up(md) {
      flex: 1 1 auto;
    }*/
}

.fli__collaborators {
  min-height: 30px;
  display: flex;
  align-items: center;
}

.fli__collaborator {
  position: relative;
  flex-shrink: 1;
  flex-basis: 20px;
  min-height: 18px;
}

.fli__collaborator-avatar {
  display: block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  object-fit: cover;
}

.sort-option-group {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 1.5;
  color: #bbb;
}

.sort-option-item {
  padding: 1px 5px;
  margin-left: 2px;
  margin-right: 2px;
}

.sort-option-item:not(.disabled).active, .sort-option-item:not(.disabled):active, .sort-option-item:not(.disabled):hover {
  color: #414141;
  cursor: pointer;
}

.sort-option-group .sort-option-item {
  margin-left: 0;
  margin-right: 0;
}

.sort-option-group.list > *:first-child {
  padding-left: 0;
}

.sort-option-group.list > *:last-child {
  padding-right: 0;
}

.sort-option-group.list .name {
  min-width: 120px;
  flex: 1 1 auto;
}

.sort-option-group.list .client {
  min-width: 100px;
  flex: 0 0 100px;
}

.sort-option-group.list .start_date {
  /*text-align: right;*/
}

.sort-option-group.list .collaborators, .sort-option-group.list .start_date {
  width: 100px;
  flex: 0 0 100px;
  min-width: 100px;
}

.sort-option-group.list .updated_at {
  text-align: right;
  width: 115px;
  flex: 0 0 115px;
  padding-right: 0;
  min-width: 60px;
}

.steps-progress {
  margin: 20px 0;
  position: relative;
  background-color: #f1f6fa;
  width: 100%;
  height: 14px;
  border-radius: 7px;
  overflow: hidden;
}

.steps-progress__progress {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  background-color: #6ed232;
  transition: width .2s;
}

.step-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 15px 0;
  color: #2c497b;
  align-items: center;
}

.step-header__step {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 2px solid #2c497b;
  margin-right: 30px;
  text-align: center;
  line-height: 45px;
  font-size: 28px;
  font-weight: 600;
}

.step-header__title {
  font-size: 20px;
  line-height: 1.1;
}

.comment-embedded {
  color: #2f2f2f;
}

.comment-embedded__content {
  position: relative;
}

.comment-embedded--has-comment .comment-embedded__content {
  padding-left: 22px;
}

.comment-embedded__text {
  margin: 0;
}

.comment-embedded__action-remove {
  padding: 0 !important;
  color: #2f2f2f;
  position: absolute;
  left: 3px;
  top: 0;
  cursor: pointer;
  transition: all .2s;
}

.comment-embedded__action-remove:hover {
  color: #2f2f2f;
}

.comment-embedded__action-add {
  padding: 0 !important;
  color: #c0c1c3;
  font-weight: 400;
  text-decoration: underline;
  line-height: 1.25;
  cursor: pointer;
  transition: all .2s;
}

.comment-embedded--pending .comment-embedded__action-add {
  color: #2f2f2f;
}

.comment-embedded__action-add:hover {
  color: #2f2f2f;
}

.sidebar-favorite {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 280px;
  background: #2c497b;
  margin-right: -280px;
  transition: margin-right 0.2s;
  top: 55px;
  right: 0;
  z-index: 1017;
  height: calc(100vh - 55px);
  color: #fff;
}

.sidebar-favorite-show .sidebar-favorite {
  margin-right: 0;
}

.sidebar-favorite__content {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}

.sidebar-favorite__close {
  top: 8px;
  left: 8px;
  color: #fff;
  cursor: pointer;
}

.sidebar-favorite-show .main {
  margin-right: 280px;
  position: relative;
  transition: margin-right .2s;
}

@media (max-width: 991.98px) {
  .sidebar-favorite-show .main {
    margin-right: 0;
  }
  .sidebar-favorite-show .main:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1016;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0.2s;
  }
}

.favorite-toggler {
  position: fixed;
  top: 90px;
  right: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
  border-radius: 0;
  margin: 0;
  pointer-events: auto;
  opacity: .9;
  z-index: 998;
  background-color: #5c6468;
  color: #f89e30;
  transition: opacity .2s;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.favorite-toggler:hover {
  opacity: 1;
}

.single-image-uploader-wrap {
  width: 148px;
  height: 148px;
}

.single-image-uploader .el-upload, .siu .el-upload {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  width: 148px;
  height: 148px;
  cursor: pointer;
}

.single-image-uploader .el-upload:hover, .siu .el-upload:hover {
  border-color: #409EFF;
}

.siu__icon-plus {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 148px;
  text-align: center;
}

.siu__picture {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.siu__picture-thumbnail {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.siu__picture-actions {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity .3s;
}

.siu__picture-actions::after {
  display: inline-block;
  content: "";
  height: 100%;
  vertical-align: middle;
}

.siu__picture-actions > span {
  display: none;
  cursor: pointer;
}

.siu__picture-actions:hover {
  opacity: 1;
}

.siu__picture-actions:hover > span {
  display: inline-block;
}

.uploader-card-container {
  /*.single-image-uploader-wrap {
    float: left;
    margin-right: 22px;
  }*/
}

.uploader-card-container .single-image-uploader-wrap,
.uploader-card-container .single-image-uploader,
.uploader-card-container .siu,
.uploader-card-container .siu .el-upload {
  width: 148px;
  height: 192.4px;
}

.uploader-card-container .siu__icon-plus {
  line-height: 192.4px;
}

.column-manager__item {
  display: flex;
}

.column-manager__item-handle {
  cursor: grab;
  padding: 2px;
}

.column-manager__item-name {
  padding: 0 10px;
  flex: 1 1 auto;
}

.column-manager__item-actions {
  display: flex;
}

.column-manager__item-action {
  padding: 2px;
  opacity: .75;
  margin-right: 4px;
  transition: opacity .2s;
  cursor: pointer;
}

.column-manager__item-action:last-child {
  margin-right: 0;
}

.column-manager__item-action:hover {
  opacity: 1;
}

.column-manager__item-remove {
  cursor: pointer;
}

.column-manager-tags {
  display: block;
  position: relative;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  min-height: 40px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}

.column-manager-tags--loading .column-manager-tags__config {
  cursor: default;
  opacity: 1;
}

.column-manager-tags__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 22px;
}

.column-manager-tags__edit {
  position: absolute;
  right: 1px;
  top: 1px;
  height: 39px;
  width: 39px;
  text-align: center;
  line-height: 38px;
  opacity: 0.75;
  cursor: pointer;
  color: #2f2f2f;
}

.column-manager-tags__edit:hover {
  opacity: 1;
}

.column-manager-tag {
  display: block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 4px 10px 4px 10px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #fff;
  background-color: #41b883;
}

.uploader-card-container:after, .clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.pull-right {
  float: right;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.link-color-b-blue {
  color: #2c497b;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}

.link-color-b-blue:hover, .link-color-b-blue:active {
  color: #2c497b;
  text-decoration: none;
  border-bottom-color: currentColor;
}

.bg-1 {
  background-color: #f1f6fa;
}

.bg-2 {
  background-color: #c5dafb;
}

.bg-3 {
  background-color: #7eaaf4;
}

.bg-4 {
  background-color: #2c497b;
}

.bg-5 {
  background-color: #e2eaf0;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.required:after {
  content: " *";
  color: red;
}

.card-header-preview {
  font-size: 28px;
  font-weight: bold;
}

.card-header-preview__body {
  display: inline-block;
  padding: 8px 22px;
}

.card-figure-preview {
  position: relative;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  width: 148px;
  height: 148px;
}

.card-figure-preview__thumbnail {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-figure-preview--text {
  cursor: pointer;
  text-align: center;
}

.card-figure-preview--text:hover {
  border-color: #409EFF;
}

.card-figure-preview__icon-plus {
  line-height: 148px;
}

.card-figure-preview__actions {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity .3s;
}

.card-figure-preview__actions::after {
  display: inline-block;
  content: "";
  height: 100%;
  vertical-align: middle;
}

.card-figure-preview__actions > span {
  display: none;
  cursor: pointer;
}

.card-figure-preview__actions:hover {
  opacity: 1;
}

.card-figure-preview__actions:hover > span {
  display: inline-block;
}

.card-preview {
  position: relative;
  margin: 22px 0;
}

.card-preview::after {
  display: block;
  clear: both;
  content: "";
}

.card-preview__front, .card-preview__back {
  position: relative;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  width: 148px;
  height: 192.4px;
  margin-right: 22px;
  float: left;
}

.card-preview__front img, .card-preview__back img {
  display: block;
  width: 100%;
  height: auto;
}

.sidebar-session {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 300px;
  background: #fff;
  margin-right: -300px;
  transition: none;
  top: 55px;
  right: 0;
  z-index: 1017;
  height: calc(100vh - 55px);
  border-left: 1px solid #c8ced3;
}

@media (min-width: 768px) {
  .sidebar-session {
    width: 360px;
    margin-right: -360px;
  }
}

.sidebar-session-show .sidebar-session {
  margin-right: 0;
}

.sidebar-session .nav-tabs .nav-item:first-child .nav-link {
  border-left: 0;
}

.sidebar-session .nav-tabs .nav-link {
  border-top: none;
  border-radius: 0;
  padding: .75rem 1rem;
}

.sidebar-session .tab-content .tab-pane {
  padding: 0;
}

.sidebar-session .tab-content {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  border: 0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.sidebar-session .tab-content {
  margin-top: 0;
  height: calc(100vh - 2.375rem - 55px);
}

.sidebar-session-show .main {
  margin-right: 300px;
  position: relative;
}

@media (min-width: 768px) {
  .sidebar-session-show .main {
    margin-right: 360px;
  }
}

@media (max-width: 991.98px) {
  .sidebar-session-show .main {
    margin-right: 0;
  }
  .sidebar-session-show .main:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1016;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0.2s;
  }
}

.comment-thread {
  margin-bottom: 10px;
  border-top: 1px solid #cbcbcb;
  border-botoom: 1px solid #cbcbcb;
  font-size: 13px;
}

.comment {
  position: relative;
  padding: 10px;
  background-color: #ebebeb;
}

.comment textarea {
  word-wrap: break-word;
  resize: none;
  overflow: hidden;
  overflow-wrap: break-word;
}

.comment__content {
  margin-top: 5px;
}

.comment__date {
  color: silver;
  font-size: 85%;
  margin-left: auto;
  flex-wrap: wrap;
}

.comment__text {
  margin-top: 5px;
  margin-bottom: 5px;
}

.comment__on {
  color: silver;
}

.comment__avatar {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.comment__author {
  display: flex;
  align-items: center;
}

.comment__resolve, .comment__cancel {
  position: absolute;
  right: 0;
  top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.comment--new {
  background-color: #ffffe3;
}

.comment--reply {
  background-color: #fff;
}

.comment--new {
  border-top: 1px solid #e5e5e5;
  background-color: #ffffe3;
  text-align: right;
}

.collaborators-list {
  margin-bottom: 1rem;
}

.collaborator-item {
  display: flex;
  align-items: center;
  padding: 6px;
  border-bottom: 1px solid #ebebeb;
}

.collaborator-item:first-child {
  border-top: 1px solid #ebebeb;
}

.collaborator-item__icon {
  width: 50px;
  padding: 4px 8px 4px 0;
  text-align: center;
}

.collaborator-item__icon-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.collaborator-item__body {
  flex: 1 1 auto;
}

body {
  background-color: #fff;
}

ol.cyrilic {
  padding-left: 16px;
}

ol.cyrilic > li {
  padding-left: 16px;
  position: relative;
  list-style: none;
}

ol.cyrilic > li:before {
  position: absolute;
  left: 0;
}

ol.cyrilic li::before {
  color: #939597;
  font-weight: bold;
}

ol.cyrilic li:nth-child(1)::before {
  content: 'А';
}

ol.cyrilic li:nth-child(2)::before {
  content: 'Б';
}

ol.cyrilic li:nth-child(3)::before {
  content: 'В';
}

ol.cyrilic li:nth-child(4)::before {
  content: 'Г';
}

ol.cyrilic li:nth-child(5)::before {
  content: 'Д';
}

ol.cyrilic li:nth-child(6)::before {
  content: 'Е';
}

ol.cyrilic li:nth-child(7)::before {
  content: 'И';
}

ol.cyrilic li:nth-child(8)::before {
  content: 'К';
}

@font-face {
  font-family: "Futura_Medium";
  src: url("../fonts/Futura_Medium.woff") format("woff");
  src: url("../fonts/Futura_Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fontello";
  src: url("../fonts/fontello.woff") format("woff");
  src: url("../fonts/fontello.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.main {
  display: flex;
  flex-direction: row;
}

.main-container {
  flex: 1;
  min-width: 0;
  position: relative;
}

.main .container-fluid {
  padding: 0 15px;
}

.carousel-header {
  font-size: 24px;
  font-weight: 600;
}

.icon-btn {
  cursor: pointer;
  color: #8d8d8d;
  background-color: transparent;
  box-shadow: none;
  text-decoration: none;
}

.icon-btn.active, .icon-btn:hover, .icon-btn:focus {
  color: #414141;
  text-decoration: none;
}

.icon-btn .btn-link {
  color: inherit;
}

.ghost-block {
  height: 85px;
  overflow: hidden;
}

.ghost-block:after {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  content: "";
  background-color: #c3c3c3;
}

.blocks-mini .ghost-block {
  height: 53px;
}

input.editable-input,
textarea.editable-input {
  width: 100%;
  font: inherit;
  background-color: transparent;
  border: none;
  resize: none;
  outline: none;
  padding: 0;
  display: block;
  cursor: text;
}

input.editable-input.editable-input--disabled {
  cursor: default;
}

input.editable-input:not(.editable-input--disabled).material-input:hover,
textarea.editable-input:not(.editable-input--disabled).material-input:hover {
  background-color: #fffab0;
}

input.editable-input.material-input:focus,
input.editable-input.material-input:hover:focus,
textarea.editable-input.material-input:focus,
textarea.editable-input.material-input:hover:focus {
  background-color: inherit;
}

[contenteditable]:hover {
  background-color: #fffab0;
  color: #414141;
}

.editable:hover {
  background-color: #fffab0;
  color: #414141;
}

[contenteditable]:focus,
[contenteditable]:hover:focus {
  background-color: inherit;
}

.editable:focus,
.editable:hover:focus {
  background-color: inherit;
}

ol, ul {
  padding-left: 25px;
}

.condensed-par ol, .condensed-par p, .condensed-par ul {
  margin-bottom: 4px;
}

[contenteditable] {
  outline: none;
}

.fa-sm {
  font-size: .66em;
}

.no-wrapper-width .swiper-wrapper {
  width: 1px;
}

.bs-tooltip {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #94b4e7;
  border-radius: 50%;
  color: #fff;
  font-size: 9px;
  cursor: pointer;
  line-height: 14px;
  text-align: center;
  vertical-align: top;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
  transition: opacity .2s;
}

.fade-fast-enter,
.fade-fast-leave-to {
  opacity: 0;
}

.session-steps-container {
  /*padding: 20px 15px;*/
  min-height: 100%;
  background-color: #fff;
  color: #2f2f2f;
}

.session-step {
  padding-bottom: 40px;
}

.session-steps-h-s {
  padding-right: 15px;
  padding-left: 15px;
}

.session-step__header-wrap {
  padding-top: 38px;
}

.session-step__header {
  text-align: center;
  font-size: 1.71428571rem;
}

.session-step-2 .s-row-1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.session-step-2 .s-row-1 .s-col:first-child {
  flex-grow: 1;
}

.session-step-2 .s-row-1 .s-col:last-child {
  width: 330px;
}

.session-step__row-daf {
  position: relative;
  overflow: hidden;
}

.session-step__row-daf-place {
  display: flex;
  flex-wrap: nowrap;
}

.session-step__row-daf-place-a {
  min-width: 0;
  flex-grow: 1;
}

.session-step__row-daf-place-b {
  width: 250px;
  position: relative;
  flex: none;
  margin-left: auto;
  display: none;
}

@media (min-width: 992px) {
  .session-step__row-daf-place-b {
    display: block;
  }
}

.session-step__row-daf-place-b:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 9999px;
  bottom: 0;
  background-color: #c5dafb;
}

.session-step__row-comment-block {
  margin-top: 20px;
  margin-bottom: 24px;
}

.session-step__cards-dest {
  padding: 38px 0 38px 0;
}

@media (min-width: 992px) {
  .session-step__cards-dest {
    padding-right: 30px;
  }
}

.session-step__favorite {
  padding: 38px 0 38px 15px;
  position: relative;
}

.session-step__favorite-header {
  margin-bottom: 32px;
}

.session-step__favorite-header-place {
  display: inline-block;
}

.session-step-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.session-step-nav > * {
  margin-top: .5rem;
}

.session-step-nav .btn-bs {
  min-width: 190px;
}

.session-step-nav__prev {
  margin-right: 16px;
}

.session-step-nav__board {
  margin-left: auto;
  cursor: pointer;
  font-size: 1.28571429rem;
}

.step-header-2 {
  font-size: 24px;
  font-weight: 600;
}

.session-step__sym-favorite {
  color: #f89e30;
  font-size: 26px;
  display: inline-block;
  margin-right: 10px;
}

.session-step .mce-content-body p {
  margin: 0 0 4px;
}

.s-card {
  position: relative;
  width: 300px;
  height: 390px;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #aaaaaa;
  transition: border-color .2s;
  overflow: hidden;
}

.s-card__pic {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.s-card__drag {
  display: none;
  position: absolute;
  color: #dde1e7;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  right: 4px;
  top: 32px;
  background-color: #94b4e7;
  transition: color .2s;
}

.s-card__drag:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: "\f255";
}

.swiper-slide-active .s-card__drag {
  cursor: grab;
}

@media (min-width: 992px) {
  .s-card__drag {
    display: block;
  }
}

.s-card__panel {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  bottom: 4px;
}

.s-card__panel--item {
  display: block;
  color: #dde1e7;
  width: 24px;
  height: 24px;
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  object-fit: contain;
  margin-top: 4px;
  transition: color .2s;
}

.s-card__panel--item.s-card__panel--item-active {
  color: #6ed232;
}

.swiper-slide-active .s-card__panel--item:not(.no-action) {
  cursor: pointer;
}

.swiper-slide-active .s-card__panel--item:not(.s-card__panel--item-active):hover {
  color: #2f2f2f;
}

.s-card__panel--item:first-child {
  margin-top: 0;
}

.s-card__favorite:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: "\f005";
}

.s-card__favorite.s-card__panel--item-active {
  color: #f89e30;
}

.s-card__edit:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: "\f040";
}

.s-card__team:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: "\F0C0";
}

.s-card__actions {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition: opacity .2s;
  background: rgba(0, 0, 0, 0.5);
  justify-items: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 32px;
  cursor: pointer;
}

.s-card__actions:hover, .s-card__actions:active {
  opacity: 1;
}

.s-card.s-card--empty {
  border: 2px solid transparent;
  cursor: pointer;
}

.s-card .s-card__content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 12px;
  top: 12px;
  right: 12px;
  bottom: 12px;
  border-radius: 10px;
  color: #c0c1c3;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid #dde1e7;
  text-align: center;
}

.s-card.src-success:before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(40, 167, 69, 0.6);
}

.slide-s-card__comment {
  margin-top: .75rem;
}

.slide-s-card__duration {
  margin-top: .5rem;
  font-size: 1.42857143rem;
  text-align: center;
}

.slide-s-card__duration + .slide-s-card__comment {
  margin-top: .5rem;
}

.s-card__close {
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  right: 4px;
  top: 4px;
  color: #dde1e7;
  background-color: #94b4e7;
  transition: color .2s;
}

.s-card__close:hover, .s-card__close:active {
  color: #2f2f2f;
}

.s-card__close:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: "\f00d";
}

.cards-src-carousel-container {
  padding-top: 38px;
  padding-bottom: 38px;
}

.cards-src-carousel-place {
  min-height: 196px;
  user-select: none;
}

.cards-src-carousel-place .swiper-inner {
  width: 100%;
  height: 400px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cards-src-carousel-place .swiper-slide {
  width: 150px;
  height: 196px;
}

.cards-src-carousel-place .s-card {
  width: 150px;
  height: 195px;
}

@media (min-width: 992px) {
  .cards-src-carousel-place {
    min-height: 392px;
  }
  .cards-src-carousel-place .swiper-slide {
    width: 300px;
    height: 392px;
  }
  .cards-src-carousel-place .s-card {
    width: 300px;
    height: 390px;
  }
}

.cards-src-carousel-place .swiper-slide-active .s-card {
  border-color: #2c497b;
}

.carousel-bs-nav .swiper-button-next,
.carousel-bs-nav .swiper-button-prev {
  width: 48px;
  height: 48px;
}

.carousel-bs-nav .swiper-button-prev {
  background: url("../img/carousel-prev.png");
  left: 0;
}

.carousel-bs-nav .swiper-button-next {
  background: url("../img/carousel-next.png");
  right: 0;
}

.cards-src-carousel {
  position: relative;
  padding: 0 56px;
  margin-top: 1rem;
}

.cards-src-carousel .swiper-button-next,
.cards-src-carousel .swiper-button-prev {
  margin-top: -22px -25px;
}

.cards-src-carousel__action-add {
  display: none;
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%);
  bottom: 10px;
}

@media (min-width: 576px) {
  .cards-src-carousel__action-add {
    display: block;
  }
}

@media (min-width: 992px) {
  .cards-src-carousel__action-add {
    transform: translate(-50%, 100%);
    bottom: 0;
  }
}

.cards-src-carousel-controls {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-top: -.5rem;
  position: relative;
}

.cards-src-carousel-controls > * {
  margin-top: .5rem;
}

@media (max-width: 767.98px) {
  .cards-src-carousel-controls > * {
    width: 100% !important;
  }
}

.cards-src-carousel-control__search {
  width: 242px;
  position: relative;
}

.cards-src-carousel-control__search:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: "\f002";
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.6rem;
  margin-top: -2px;
}

@media (min-width: 992px) {
  .cards-src-carousel-control__search {
    width: 292px;
  }
}

.cards-src-carousel-control__search .search-input {
  padding: .375rem 3rem .375rem 1rem;
}

.cards-src-carousel__control-add {
  /*margin-left: auto;
  margin-right: auto;*/
}

@media (min-width: 768px) {
  .cards-src-carousel__control-add {
    margin-left: 50%;
    transform: translate(-50%);
    position: absolute;
  }
}

.cards-src-carousel-box {
  display: flex;
  flex-direction: row;
}

.cards-src-carousel-box > *:first-child {
  flex: 1;
}

.cards-src-carousel-box > *:nth-child(2) {
  position: relative;
  margin-left: 30px;
  display: none;
  flex-direction: column;
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .cards-src-carousel-box > *:nth-child(2) {
    display: flex;
  }
}

.cards-dest-carousel-container .swiper-button-next,
.cards-dest-carousel-container .swiper-button-prev {
  position: static;
  top: auto;
  margin-top: auto;
}

.cards-dest-carousel-place-wrap {
  position: relative;
}

.cards-dest-carousel-place-wrap.dest-success .cards-dest-carousel-zone {
  color: #28a745;
}

.cards-dest-carousel-place-wrap.dest-success .cards-dest-carousel-zone:after {
  opacity: 1;
  transition: opacity .2s;
}

.cards-dest-carousel-place-wrap--drag-receiver {
  /*  .cards-dest-carousel-zone {
    display: block;
  }*/
}

.cards-dest-carousel-zone {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  color: #2c497b;
}

.cards-dest-carousel-zone:before {
  content: "";
  display: block;
  position: absolute;
  border: 2px dashed currentColor;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  transition: border .2s;
}

.cards-dest-carousel-zone:after {
  display: block;
  content: "\f067";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  position: absolute;
  font-size: 64px;
  left: 50%;
  top: 50%;
  opacity: 0;
  margin-top: -32px;
  margin-left: -32px;
  transition: color .2s;
}

.cards-dest-carousel-place {
  min-height: 196px;
  user-select: none;
}

.cards-dest-carousel-place .swiper-inner {
  width: 100%;
  height: 400px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cards-dest-carousel-place .swiper-wrapper {
  align-items: stretch;
}

.cards-dest-carousel-place .swiper-slide {
  width: 150px;
}

.cards-dest-carousel-place .s-card {
  width: 150px;
  height: 195px;
}

@media (min-width: 992px) {
  .cards-dest-carousel-place {
    min-height: 342px;
  }
  .cards-dest-carousel-place .swiper-slide {
    width: 262px;
    height: auto;
  }
  .cards-dest-carousel-place .s-card {
    width: 262px;
    height: 340px;
  }
}

.cards-dest-carousel {
  position: relative;
}

.cards-dest-carousel__header {
  display: flex;
  justify-content: center;
  font-size: 24px;
  align-items: center;
  margin-bottom: 32px;
}

.cards-dest-carousel__header .swiper-button-prev,
.cards-dest-carousel__header .swiper-button-next {
  width: 32px;
  height: 32px;
}

.cards-dest-carousel__header .swiper-button-prev {
  background: url("../img/carousel-s-prev.png");
}

.cards-dest-carousel__header .swiper-button-next {
  background: url("../img/carousel-s-next.png");
}

.cards-dest-carousel__header-place {
  margin: 0 20px;
}

.uploader-card-container:after, .clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.pull-right {
  float: right;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.link-color-b-blue {
  color: #2c497b;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}

.link-color-b-blue:hover, .link-color-b-blue:active {
  color: #2c497b;
  text-decoration: none;
  border-bottom-color: currentColor;
}

.bg-1 {
  background-color: #f1f6fa;
}

.bg-2 {
  background-color: #c5dafb;
}

.bg-3 {
  background-color: #7eaaf4;
}

.bg-4 {
  background-color: #2c497b;
}

.bg-5 {
  background-color: #e2eaf0;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.required:after {
  content: " *";
  color: red;
}

/*.favorites {
  display: flex;
  !*flex-wrap: wrap;*!
  margin-left: -12px;
  margin-top: -16px;
  overflow-x: auto;
}*/
.favorite-item.s-card {
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-radius: 3px;
}

.favorite-item.s-card img {
  border-radius: 3px;
}

.favorite-item__close {
  position: absolute;
  display: block;
  right: 2px;
  top: 2px;
  cursor: pointer;
  opacity: .8;
  line-height: 1;
}

.favorite-item__close:hover, .favorite-item__close:active {
  opacity: 1;
}

.favorite-item__close:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: "\f00d";
}

.session-steps-panel-favorite {
  margin: 20px 0;
}

.session-steps-panel-favorite .swiper-container {
  margin-top: 15px;
}

.swiper-slide {
  width: 84.61538462px;
  height: 110px;
}

@media (min-width: 992px) {
  .swiper-slide {
    width: 110px;
    height: 143px;
  }
}

.btn-green {
  color: #23282c;
  background-color: #6ed232;
  border-color: #6ed232;
  box-shadow: none;
  color: #fff;
}

.btn-green:hover {
  color: #fff;
  background-color: #5db628;
  border-color: #58ab26;
}

.btn-green:focus, .btn-green.focus {
  color: #fff;
  background-color: #5db628;
  border-color: #58ab26;
  box-shadow: 0 0 0 0.2rem rgba(99, 185, 49, 0.5);
}

.btn-green.disabled, .btn-green:disabled {
  color: #23282c;
  background-color: #6ed232;
  border-color: #6ed232;
}

.btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
.show > .btn-green.dropdown-toggle {
  color: #fff;
  background-color: #58ab26;
  border-color: #52a123;
}

.btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 185, 49, 0.5);
}

.btn-bs {
  padding: 0 35px;
  height: 42px;
}

.btn-blaze {
  color: #23282c;
  background-color: #c5dafb;
  border-color: #c5dafb;
  box-shadow: none;
  color: #2f2f2f;
}

.btn-blaze:hover {
  color: #23282c;
  background-color: #a1c3f9;
  border-color: #95bcf8;
}

.btn-blaze:focus, .btn-blaze.focus {
  color: #23282c;
  background-color: #a1c3f9;
  border-color: #95bcf8;
  box-shadow: 0 0 0 0.2rem rgba(173, 191, 220, 0.5);
}

.btn-blaze.disabled, .btn-blaze:disabled {
  color: #23282c;
  background-color: #c5dafb;
  border-color: #c5dafb;
}

.btn-blaze:not(:disabled):not(.disabled):active, .btn-blaze:not(:disabled):not(.disabled).active,
.show > .btn-blaze.dropdown-toggle {
  color: #23282c;
  background-color: #95bcf8;
  border-color: #89b4f7;
}

.btn-blaze:not(:disabled):not(.disabled):active:focus, .btn-blaze:not(:disabled):not(.disabled).active:focus,
.show > .btn-blaze.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 191, 220, 0.5);
}

.btn-blaze-secondary {
  color: #23282c;
  background-color: #f1f6fa;
  border-color: #f1f6fa;
  box-shadow: none;
  color: #2f2f2f;
}

.btn-blaze-secondary:hover {
  color: #23282c;
  background-color: #d5e4f0;
  border-color: #cbdeed;
}

.btn-blaze-secondary:focus, .btn-blaze-secondary.focus {
  color: #23282c;
  background-color: #d5e4f0;
  border-color: #cbdeed;
  box-shadow: 0 0 0 0.2rem rgba(210, 215, 219, 0.5);
}

.btn-blaze-secondary.disabled, .btn-blaze-secondary:disabled {
  color: #23282c;
  background-color: #f1f6fa;
  border-color: #f1f6fa;
}

.btn-blaze-secondary:not(:disabled):not(.disabled):active, .btn-blaze-secondary:not(:disabled):not(.disabled).active,
.show > .btn-blaze-secondary.dropdown-toggle {
  color: #23282c;
  background-color: #cbdeed;
  border-color: #c2d8e9;
}

.btn-blaze-secondary:not(:disabled):not(.disabled):active:focus, .btn-blaze-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-blaze-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 215, 219, 0.5);
}

.search-input {
  background-color: #f1f6fa;
}

.input-pill {
  border-radius: 50em;
}

.input-bs {
  height: 42px;
  padding: 0.375rem 1rem;
}

.v-icon {
  display: inline-block;
}

.v-icon-arrow-v-right {
  width: 28px;
  height: 20px;
  background: url("../img/icons/arrow-v-right.png") no-repeat 0 0;
}

.yandex-brands {
  background-image: url("../img/icons/yandex-brands.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.yandex-brands:before {
  content: '';
  display: block;
  width: 8px;
  height: 16px;
}

.panel-a-actions {
  display: flex;
  flex-wrap: wrap;
}

.panel-a-action {
  transition: color .2s;
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 5px;
}

.panel-a-action:last-of-type {
  margin-right: 0;
}

.panel-a-action span {
  display: flex;
  align-items: center;
}

.panel-a-action .fa {
  font-size: 26px;
  color: #dde1e7;
  margin-right: .5rem;
  transition: color .2s;
}

.panel-a-action:hover .fa {
  color: #2f2f2f;
}

.panel-a-action--yellow .fa {
  color: #f89e30;
}

.panel-a-action--yellow:hover .fa {
  color: #f89e30;
}

.panel-a-action--green .fa {
  color: #6ed232;
}

.panel-a-action--green:hover .fa {
  color: #6ed232;
}

.t-color .nav-link {
  color: #2c497b;
}

.t-color .nav-link.active {
  opactity: 0.5 !important;
  background-color: #2c497b;
  color: #fff;
}

.t-color .card.border-primary {
  border-color: #2c497b !important;
}

.t-color .tab-pane .accordion-name:focus {
  background-color: #20c997;
}

.t-color .tab-pane .accordion-name {
  text-align: left;
  border-color: unset;
  background-color: #20c997;
  color: #fff;
}

.t-color .tab-pane .accordion-block, .t-color .tab-pane .accordion-block:focus {
  border: unset;
}

.header-tariff_name, .header-tariff_price {
  padding: 0.75rem 1.25rem;
  color: #fff;
  background-color: #2c497b;
  font-size: 18px;
}

.header-tariff_price {
  font-size: 16px;
  opacity: .7;
}

.tariff-cards .card-header {
  padding: 0;
}

.tariff-cards .header-tariff_name, .tariff-cards .header-tariff_price, .tariff-cards p {
  text-align: center;
}

.cards-carousel-box {
  display: flex;
  flex-direction: row;
}

.cards-carousel-box > *:first-child {
  flex: 1;
}

.cards-carousel-box > *:nth-child(2) {
  position: relative;
  margin-left: 30px;
  display: none;
  flex-direction: column;
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .cards-carousel-box > *:nth-child(2) {
    display: flex;
  }
}

.cards-carousel {
  position: relative;
  padding: 0 56px;
  margin-top: 1rem;
}

.cards-carousel .swiper-button-next,
.cards-carousel .swiper-button-prev {
  margin-top: -22px -25px;
}

.cards-carousel .swiper-button-next::after,
.cards-carousel .swiper-button-prev::after {
  display: none;
}

.cards-carousel-place {
  min-height: 196px;
  user-select: none;
}

.cards-carousel-place .swiper-inner {
  width: 100%;
  height: 400px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cards-carousel-place .swiper-slide {
  width: 150px;
  height: 196px;
}

.cards-carousel-place .s-card {
  width: 150px;
  height: 195px;
}

@media (min-width: 992px) {
  .cards-carousel-place {
    min-height: 392px;
  }
  .cards-carousel-place .swiper-slide {
    width: 300px;
    height: 392px;
  }
  .cards-carousel-place .s-card {
    width: 300px;
    height: 390px;
  }
}

.cards-carousel-place .swiper-slide-active .s-card {
  border-color: #2c497b;
}

.m-card-container {
  display: flex;
  height: 100%;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
}

.m-card-header {
  margin-bottom: 1rem;
  font-weight: bold;
}

.m-card-wrap {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  justify-content: center;
}

@media (min-width: 992px) {
  .m-card-wrap {
    grid-template-columns: repeat(auto-fit, minmax(300px, 470px));
  }
}

.m-card {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #aaaaaa;
  overflow: hidden;
}

.m-card__pic {
  max-width: 100%;
  height: auto;
}

.m-card-figures {
  display: grid;
  grid-template-columns: repeat(auto-fit, 128px);
  grid-gap: 1rem;
  justify-content: center;
  max-height: 272px;
  overflow: auto;
}

@media (min-width: 768px) {
  .m-card-figures {
    grid-template-columns: repeat(auto-fit, 138px);
    max-height: 292px;
  }
}

@media (min-width: 992px) {
  .m-card-figures {
    grid-template-columns: repeat(auto-fit, 170px);
    max-height: 356px;
  }
}

.m-card-figure {
  position: relative;
  padding-bottom: 100%;
  background: no-repeat center center;
  background-size: contain;
  cursor: pointer;
}

.form-item__error {
  color: #F56C6C;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

.form-item {
  margin-bottom: 22px;
}

.col-form-label, .form-item__label {
  display: block;
  vertical-align: middle;
  font-weight: bold;
}

.iw-container {
  background-color: #fff;
  padding: 0 !important;
}

.iw-grid-container > div {
  padding: 1rem;
  display: grid;
  overflow-y: auto;
}

.iw-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.iw-item-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(146px, max-content));
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
}

.iw-grid-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: .5rem;
}

.iw-item-container--request {
  background-color: rgba(237, 27, 36, 0.1);
}

.iw-item-container--result {
  background-color: rgba(0, 114, 187, 0.1);
}

.iw-item-container--module-group {
  background-color: rgba(12, 177, 75, 0.1);
}

.iw-item-container--module-group > .iw-item-container > .iw-item-group {
  background-color: #dedeef;
}

.iw-item-container--module-group > .iw-item-container > .iw-item-group:nth-child(2n) {
  background-color: #fff2d8;
}

.iw-item-container--module-group > .iw-item-container > .iw-item-group:last-child {
  background-color: transparent;
}

.iw-item-group {
  position: relative;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}

.iw-item-group--no-nested {
  display: block;
}

.iw-item-container--track {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(149, 164, 171, 0.2);
  padding-top: 22px;
  position: relative;
}

.track-block-time-wrap {
  position: absolute;
  left: 8px;
  top: 4px;
}

/*.iw-item-group--track {
  display: block;
}*/
.iw-item-sub--track {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iw-item-sub[class*="level"]:not(.iw-item-container--track) {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  /*  .iw-item-group {
      display: block;
    }*/
}

.iw-item-sub {
  min-height: 30px;
  justify-items: center;
  min-width: 130px;
  grid-template-columns: repeat(auto-fit, minmax(130px, max-content));
}

.iw-item-wrap {
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iw-item-group > .iw-item-wrap {
  margin: 8px 0 0;
}

.iw-item-group--track > .iw-item-wrap {
  margin: 0 8px;
}

.iw-item--ms {
  padding-bottom: 8px;
}

.iw-item-container--request .iw-item-group--no-nested > .iw-item-wrap,
.iw-item-container--request .iw-item-group.filtered > .iw-item-wrap,
.iw-item-container--result .iw-item-group--no-nested > .iw-item-wrap,
.iw-item-container--result .iw-item-group.filtered > .iw-item-wrap {
  padding-bottom: 0;
}

.iw-item-main {
  width: 130px;
}

.iw-item--dummy .iw-card__actions {
  display: none;
}

.iw-item {
  display: flex;
  flex-wrap: wrap;
}

.iw-card {
  position: relative;
  width: 130px;
  height: 169px;
  background: #fff no-repeat center center;
  background-size: cover;
  border-radius: 5px;
  border: 1px solid darkgrey;
}

.iw-card__actions {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 4px;
  right: 4px;
}

.iw-card__action {
  margin-top: 5px;
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer !important;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  color: #dde1e7;
  /*color: #8d8d8d;*/
  background-color: #94b4e7;
  transition: color .2s, opacity .2s;
  opacity: .75;
  font-size: 13px;
}

.iw-card__action:first-child {
  margin-top: 0;
}

.iw-card__action:hover {
  opacity: 1;
  color: #2f2f2f;
}

.iw-card__action .btn {
  padding: 0;
  width: 100%;
  color: inherit;
  line-height: 1;
}

.iw-card__action .dropdown-menu {
  min-width: auto;
}

.iw-card__action .dropdown-item i {
  margin: 0;
}

.iw-card--action-remove:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: "\f00d";
}

.iw-card--track {
  display: flex;
  justify-content: center;
  align-content: center;
  background: #fff;
}

.iw-card--track > .iw-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iw-card--track > .iw-item-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iw-card--add {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
}

.iw-card--add:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-family: "Futura Medium", sans-serif;
  position: absolute;
  content: "+";
  font-size: 34px;
}

.iw-card__pic {
  display: block;
  max-width: 100%;
  height: auto;
}

.iw-grid-container .ghost-grid-card {
  position: relative;
  height: 169px;
  width: 130px;
  margin-top: 8px;
  overflow: hidden;
}

.iw-grid-container .ghost-grid-card:after {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  content: "";
  background-color: #c3c3c3;
}

.iw-grid-container .drag-item {
  cursor: grab;
}

.iw-grid-container .sortable-drag {
  overflow: hidden;
}

.iw-grid-container .sortable-drag:after {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  content: "";
  background-color: #3c5d96;
}

.iw-item-container--request .ghost-grid-card,
.iw-item-container--result .ghost-grid-card {
  margin-top: 0;
  margin-left: 8px;
}

.iw-item-container.iw-item-container--show-time .ghost-grid-card {
  height: 187px;
}

.iw-item-container--module-group > .iw-item-container > .iw-item-group .ghost-grid-card {
  margin: 8px 0 0;
}

.iw-item-container--module-group > .iw-item-container > .iw-item-group.ghost-grid-card {
  margin: 8px 8px 0;
}

.iw-grid--horizontal {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.iw-grid--horizontal .ghost-grid-card {
  min-width: 130px;
}

.iw-item-container--request .iw-grid--horizontal .ghost-grid-card,
.iw-item-container--result .iw-grid--horizontal .ghost-grid-card {
  margin-top: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.iw-grid--horizontal.iw-item-container {
  display: flex;
  justify-content: safe center;
  flex-wrap: nowrap;
  user-select: none;
  white-space: nowrap;
  margin-bottom: 8px;
  padding-bottom: 8px;
  position: relative;
  right: 0;
}

.iw-grid--horizontal .iw-item-group > .iw-item-wrap {
  margin: 8px 4px;
}

.iw-grid--horizontal > .iw-item-group > .iw-item-wrap {
  margin: 8px 4px 0;
}

.iw-grid--horizontal .iw-item-sub > .iw-item-group > .iw-item-wrap {
  margin-bottom: 0;
}

.iw-grid--horizontal .iw-item-container--track {
  flex-wrap: nowrap;
}

.iwv-grid-view-container {
  display: grid;
  overflow-y: auto;
}

.iwv--container {
  background-color: #fff;
}

.iwv-grid-container > div {
  padding: 1rem;
}

.iwv-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.iwv-day-name {
  margin: 8px;
  white-space: nowrap;
}

.iwv-item-container {
  display: flex;
  position: relative;
  right: 0;
  justify-content: safe center;
  align-content: flex-start;
  align-items: flex-start;
}

.iwv-grid-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: .5rem;
}

.iwv-item-group {
  position: relative;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}

.iwv-item-group--no-nested {
  display: block;
}

.iwv-item-sub--track {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iwv-item-sub[class*="level"]:not(.iwv-item-container--track) {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.iwv-item-group-name {
  display: none;
  margin-left: 8px;
  margin-right: auto;
}

.iwv-item-group.iwv-item-group--module:not(.iw-item-group--no-nested) > .iwv-item-group-name {
  display: block;
}

.iwv-item {
  display: flex;
  flex-wrap: wrap;
  margin: 8px;
  overflow: hidden;
}

.iwv-card {
  position: relative;
  width: 130px;
  height: 169px;
  background: #fff no-repeat center center;
  background-size: cover;
  border-radius: 5px;
  border: 1px solid darkgrey;
}

.iwv-card--track {
  display: flex;
  justify-content: center;
  align-content: center;
  background: #fff;
}

.iwv-card--track > .iwv-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iwv-card--track > .iwv-item-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iwv-card__pic {
  display: block;
  max-width: 100%;
  height: auto;
}

*, *:before, *:after {
  outline: none;
}

html, body {
  min-width: 320px;
  height: 100%;
}

#app {
  height: 100%;
}
