.session-card-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
}

.session-card__actions {
  display: flex;
  justify-content: center;
}

.session-card__action {
  cursor: pointer;
  font-size: 2em;
}

.session-card {
  position: relative;
  transform-style: preserve-3d;
  perspective: 800px;
  transition: 1s;
  width: 308px;
  height: 400px;
  border-radius: 10px;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid #dfdfdf;
}

.session-card__front, .session-card__back {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 10px;

  img  {
    border-radius: 10px;
  }
}

.session-card__back {
  transform: rotateY(180deg);
}

.session-card__pic {
  object-fit: contain;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.session-card--flip {
  transform: rotateY(180deg);
}
