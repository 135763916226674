$color-1: #f1f6fa;
$color-2: #c5dafb;
$color-3: #7eaaf4;
$color-4: #2c497b;
$color-5: #e2eaf0;
$color-6: #6ed232;
$color-7: #94b4e7;
$color-8: #fff685;
$color-9: #2f2f2f;
$color-10: #989898;
$color-11: #f89e30;
