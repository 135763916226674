.v-icon {
  display: inline-block;
}

.v-icon-arrow-v-right {
  width: 28px;
  height: 20px;
  background: url('../img/icons/arrow-v-right.png') no-repeat 0 0;
}

.yandex-brands{
  background-image: url("../img/icons/yandex-brands.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.yandex-brands:before{
  content: '';
  display: block;
  width: 8px;
  height: 16px;
}
