.column-manager {

}

.column-manager__item {
  display: flex;
}

.column-manager__item-handle {
  cursor: grab;
  padding: 2px;
}

.column-manager__item-name {
  padding: 0 10px;
  flex: 1 1 auto;
}

.column-manager__item-actions {
  display: flex;
}

.column-manager__item-action {
  padding: 2px;
  opacity: .75;
  margin-right: 4px;
  transition: opacity .2s;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 1;
  }
}

.column-manager__item-remove {
  cursor: pointer;
}

.column-manager-tags {
  display: block;
  position: relative;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  min-height: 40px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}

.column-manager-tags--loading {
  .column-manager-tags__config {
    cursor: default;
    opacity: 1;
  }
}

.column-manager-tags__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 22px;
}

.column-manager-tags__edit {
  position: absolute;
  right: 1px;
  top: 1px;
  height: 39px;
  width: 39px;
  text-align: center;
  line-height: 38px;
  opacity: 0.75;
  cursor: pointer;
  color: $color-9;

  &:hover {
    opacity: 1;
  }
}

.column-manager-tag {
  display: block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 4px 10px 4px 10px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #fff;
  background-color: #41b883;
}
