.btn-green {
  @include button-variant(#6ed232, #6ed232);
  color: #fff;
}

.btn-bs {
  padding: 0 35px;
  height: 42px;
}

.btn-blaze {
  @include button-variant(#c5dafb, #c5dafb);
  color: #2f2f2f;
}

.btn-blaze-secondary {
  @include button-variant(#f1f6fa, #f1f6fa);
  color: #2f2f2f;
}
