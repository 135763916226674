$iwv-card-width: 130px;
$iwv-card-height: $iwv-card-width * 1.3;
$iwv-grid-gap: 16px;

.iwv-grid-view-container {
  display: grid;
  overflow-y: auto;
}

.iwv--container {
  background-color: #fff;
}

.iwv-grid-container {
  & > div {
    padding: 1rem;
  }
}

.iwv-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.iwv-day-name {
  margin: 8px;
  white-space: nowrap;
}

.iwv-item-container {
  display: flex;
  position: relative;
  right: 0;
  justify-content: safe center;
  align-content: flex-start;
  align-items: flex-start;
}

.iwv-grid-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: .5rem;
}

.iwv-item-group {
  position: relative;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}

.iwv-item-group--no-nested {
  display: block;
}

.iwv-item-sub--track {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iwv-item-sub[class*="level"]:not(.iwv-item-container--track) {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.iwv-item-group-name {
  display: none;
  margin-left: 8px;
  margin-right: auto;
}

.iwv-item-group.iwv-item-group--module:not(.iw-item-group--no-nested) {
  & > .iwv-item-group-name {
    display: block;
  }
}

.iwv-item {
  display: flex;
  flex-wrap: wrap;
  margin: 8px;
  overflow: hidden;
}

.iwv-card {
  position: relative;
  width: $iwv-card-width;
  height: $iwv-card-height;
  background: #fff no-repeat center center;
  background-size: cover;
  border-radius: 5px;
  border: 1px solid darkgrey;
}

.iwv-card--track {
  display: flex;
  justify-content: center;
  align-content: center;
  background: #fff;

  & > .iwv-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > .iwv-item-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.iwv-card__pic {
  display: block;
  max-width: 100%;
  height: auto;
}
