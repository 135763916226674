.cards-carousel-container {

}

.cards-carousel-box {
  display: flex;
  flex-direction: row;

  & > *:first-child {
    flex: 1;
  }

  & > *:nth-child(2) {
    position: relative;
    margin-left: 30px;
    display: none;
    flex-direction: column;
    justify-content: flex-end;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
}

.cards-carousel {
  position: relative;
  padding: 0 56px;
  margin-top: 1rem;

  .swiper-button-next,
  .swiper-button-prev {
    margin-top: -22px -25px;

    &::after {
      display: none;
    }
  }
}

.cards-carousel-place {
  min-height: 196px;
  user-select: none;

  .swiper-inner {
    width: 100%;
    height: 400px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    width: 150px;
    height: 196px;
  }

  .s-card {
    width: 150px;
    height: 195px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 392px;

    .swiper-slide {
      width: 300px;
      height: 392px;
    }

    .s-card {
      width: 300px;
      height: 390px;
    }
  }

  .swiper-slide-active {
    .s-card {
      border-color: #2c497b;
    }
  }
}
