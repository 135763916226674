.sidebar-opened {}

.sidebar-closed .sidebar-panel {
  left: -15rem;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 10001;
  width: 15rem;
  background-color: rgba(30, 33, 41, 0.96);
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
  transition: left .3s cubic-bezier(.35,0,.25,1);
  overflow: hidden;
}

.sidebar-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0,0,0,.6);
  transition: all .3s cubic-bezier(.35,0,.25,1);
}

.sidebar-backdrop.visible {
  visibility: visible;
  opacity: 1;
}