$sidebar-favorite-width: 280px;

.sidebar-favorite {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 280px;
  background: #2c497b;
  margin-right: -$sidebar-favorite-width;
  transition: margin-right 0.2s;
  top: 55px;
  right: 0;
  z-index: 1017;
  height: calc(100vh - 55px);
  color: #fff;

  @include media-breakpoint-down(md) {

  }

  .sidebar-favorite-show & {
    margin-right: 0;
  }
}

.sidebar-favorite__content {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}

.sidebar-favorite__close {
  //position: absolute;
  top: 8px;
  left: 8px;
  color: #fff;
  cursor: pointer;
}

.sidebar-favorite-show {
  .main {
    margin-right: $sidebar-favorite-width;
    position: relative;
    transition: margin-right .2s;
  }

  @include media-breakpoint-down(md) {
    .main {
      margin-right: 0;
    }

    .main:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1016;
      width: 100%;
      height: 100%;
      content: "";
      background: rgba(0, 0, 0, 0.7);
      transition: opacity 0.2s;
    }
  }
}

.favorite-toggler {
  position: fixed;
  top: 90px;
  right: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
  border-radius: 0;
  margin: 0;
  pointer-events: auto;
  opacity: .9;
  z-index: 998;
  background-color: #5c6468;
  color: $color-11;
  transition: opacity .2s;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .2);

  &:hover {
    opacity: 1;
  }
}
