$favorite-width: 110px;
$favorite-height: $favorite-width * 1.3;

$favorite-width-mobile: $favorite-width / 1.3;
$favorite-height-mobile: $favorite-width-mobile * 1.3;

/*.favorites {
  display: flex;
  !*flex-wrap: wrap;*!
  margin-left: -12px;
  margin-top: -16px;
  overflow-x: auto;
}*/

.favorite-item {
  &.s-card {
    width: 100%;
    height: 100%;
    border-width: 1px;
    border-radius: 3px;

    img {
      border-radius: 3px;
    }
  }
}

.favorite-item__close {
  position: absolute;
  display: block;
  right: 2px;
  top: 2px;
  cursor: pointer;
  opacity: .8;
  line-height: 1;

  &:hover, &:active {
    opacity: 1;
  }

  &:before {
    @include fa-icon;
    content: "\f00d";
  }
}

.session-steps-panel-favorite {
  margin: 20px 0;

  .swiper-container {
    margin-top: 15px;
  }

}

.swiper-slide {
  width: $favorite-width-mobile;
  height: $favorite-height-mobile;
}

@include media-breakpoint-up(lg) {
  .swiper-slide {
    width: $favorite-width;
    height: $favorite-height;
  }
}
