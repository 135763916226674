@import "partials/front/index";

html, body {
  min-width: 320px;
  height: 100%;
}

#app {
  height: 100%;
}
