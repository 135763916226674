.search-input {

  background-color: #f1f6fa;
}

.input-pill {
  border-radius: 50em;
}

.input-bs {
  height: 42px;
  padding: 0.375rem 1rem;
}
