.comments {

}

.comment-thread {
  margin-bottom: 10px;
  border-top: 1px solid #cbcbcb;
  border-botoom: 1px solid #cbcbcb;
  font-size: 13px;
}

.comment {
  position: relative;
  padding: 10px;
  background-color: #ebebeb;

  textarea {
    word-wrap: break-word;
    resize: none;
    overflow: hidden;
    overflow-wrap: break-word;
  }
}

.comment__content {
  margin-top: 5px;
}

.comment__date {
  color: silver;
  font-size: 85%;
  margin-left: auto;
  flex-wrap: wrap;
}

.comment__text {
  margin-top: 5px;
  margin-bottom: 5px;
}

.comment__on {
  color: silver;
}

.comment__avatar {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.comment__author {
  display: flex;
  align-items: center;
}

.comment__resolve, .comment__cancel {
  position: absolute;
  right: 0;
  top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.comment--new {
  background-color: #ffffe3;
}

.comment--reply {
  background-color: #fff;
}

.comment--new {
  border-top: 1px solid #e5e5e5;
  background-color: #ffffe3;
  text-align: right;
}
