.session-editor {
  background: #f4f4f4;
  padding: 1em;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.session-total-time {
  display: flex;
}

.session-total-time__icon {
  display: none;
  color: red;
  padding-right: 6px;
  align-items: center;
}

.session-total-time--overlap {
  .session-total-time__icon {
    display: block;
  }
}

.timepicker {
  width: 160px;

  table {
    margin: 0 auto;
  }

  /*.btn {
    display: block;
  }*/
}

.session-editor__header {
  .col-form-label {
    padding-top: 0;
    padding-bottom: 5px;
  }

  .form-group {
    //margin-bottom: .5rem;
  }
}

.session-editor__row-header {
  display: grid;
}

.session-editor__header-row-extra-columns {
  .col-md-4 {
    min-width: 280px;
  }
}
