.step-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 15px 0;
  color: #2c497b;
  align-items: center;
}

.step-header__step {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 2px solid #2c497b;
  margin-right: 30px;
  text-align: center;
  line-height: 45px;
  font-size: 28px;
  font-weight: 600;
}

.step-header__title {
  font-size: 20px;
  line-height: 1.1;
}
