.collaborators-box {

}

.collaborators-list {
  margin-bottom: 1rem;
}

.collaborator-item {
  display: flex;
  align-items: center;
  padding: 6px;
  border-bottom: 1px solid #ebebeb;
  &:first-child {
    border-top: 1px solid #ebebeb;
  }
}

.collaborator-item__icon {
  width: 50px;
  padding: 4px 8px 4px 0;
  text-align: center;
}

.collaborator-item__icon-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.collaborator-item__body {
  flex: 1 1 auto;
}

.collaborator-item__actions {

}
