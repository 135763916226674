@import "~bootstrap/scss/mixins";

$fa-font-size-base:   16px !default;
$fa-line-height-base: 1 !default;

@mixin fa-icon() {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
