body {
  background-color: #fff;
}

ol.cyrilic {
  padding-left: 16px;

  & > li {
    padding-left: 16px;
    position: relative;
    list-style: none;

    &:before {
      position: absolute;
      left: 0;
    }
  }

  li::before {
    color: #939597;
    font-weight: bold;
  }

  li:nth-child(1)::before {
    content: 'А';
  }

  li:nth-child(2)::before {
    content: 'Б';
  }

  li:nth-child(3)::before {
    content: 'В';
  }

  li:nth-child(4)::before {
    content: 'Г';
  }

  li:nth-child(5)::before {
    content: 'Д';
  }

  li:nth-child(6)::before {
    content: 'Е';
  }

  li:nth-child(7)::before {
    content: 'И';
  }

  li:nth-child(8)::before {
    content: 'К';
  }
}
