.carousel-header {
  font-size: 24px;
  font-weight: 600;
}

.icon-btn {
  cursor: pointer;
  color: #8d8d8d;
  background-color: transparent;
  box-shadow: none;
  //padding: 0;
  text-decoration: none;

  &.active, &:hover, &:focus {
    color: #414141;
    text-decoration: none;
  }

  .btn-link {
    color: inherit;
  }
}

.ghost-block {
  height: 85px;
  overflow: hidden;

  &:after {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    content: "";
    background-color: #c3c3c3;
  }
}

.blocks-mini {
  .ghost-block {
    height: 53px;
  }
}

input.editable-input,
textarea.editable-input {
  width: 100%;
  font: inherit;
  background-color: transparent;
  border: none;
  resize: none;
  outline: none;
  padding: 0;
  display: block;
  cursor: text;
}

input.editable-input.editable-input--disabled {
  cursor: default;
}

input.editable-input:not(.editable-input--disabled).material-input:hover,
textarea.editable-input:not(.editable-input--disabled).material-input:hover {
  background-color: #fffab0;
}

input.editable-input.material-input:focus,
input.editable-input.material-input:hover:focus,
textarea.editable-input.material-input:focus,
textarea.editable-input.material-input:hover:focus {
  background-color: inherit;
}

[contenteditable]:hover {
  background-color: #fffab0;
  color: #414141;
}

.editable:hover {
  background-color: #fffab0;
  color: #414141;
}

[contenteditable]:focus,
[contenteditable]:hover:focus {
  background-color: inherit;
}

.editable:focus,
.editable:hover:focus {
  background-color: inherit;
}

ol, ul {
  padding-left: 25px;
}

.condensed-par {
  ol, p, ul {
    margin-bottom: 4px;
  }
}

[contenteditable] {
  outline: none;
}

.fa-sm {
  font-size: .66em;
}

.no-wrapper-width .swiper-wrapper {
  width: 1px;
}

.bs-tooltip {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #94b4e7;
  border-radius: 50%;
  color: #fff;
  font-size: 9px;
  cursor: pointer;
  line-height: 14px;
  text-align: center;
  vertical-align: top;
}
