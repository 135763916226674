.view-table-wrap {
  height: 100%;
  width: 100%;
}

.view-table {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.folder-toolbar {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.folder-toolbar-header, .fth {
  display: flex;
  padding: 10px 15px 0;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
}

.fth-row {
  padding: 0 10px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fth-row__content {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  align-items: center;
}

.fth-row__column {
  flex-grow: 1;
  padding: 5px;
  width: 120px;
}

.fth-row__actions {
  .btn-sm {
    padding: 5px;
  }
}

.fth__breadcrumb {
  flex: 3 1 155px;

  .breadcrumb {
    padding: 6px 12px 6px 0;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: none;
  }
}

.fth__actions {
  display: flex;
}

.view-row {
  display: table-row;

  &.scrollable {
    height: 100%;
  }
}

.list-view {

}

.view-wrapper {
  position: relative;
  height: 100%;
}

.view-cell {
  display: table-cell;
}
