.block {
  position: relative;
  background-color: #fff;
  min-height: 85px;
  margin: 5px 20px 10px;
}

.block__header > .block-actions {
  padding: 3px;
  margin-left: auto;

  & > .btn-group, & > .icon-btn {
    min-width: 19px;
  }

  .icon-btn, .btn {
    padding: .25rem 0;
    margin: 0 3px;
    font-size: small;
    color: #8d8d8d;

    .icon-btn, .btn {
      padding: 0;
      margin: 0;
    }

    &.active, &:hover:focus {
      color: #414141;
      text-decoration: none;
    }

    &:active:focus, &.active:focus {
      box-shadow: none;
    }
  }
}

.block__content {
}

.block--has-time-gap {
  margin-top: 25px !important;
}

.block__time-overlap {
  font-size: .85rem;
  color: red;
  margin-left: 6px;
}

.block__header {
  display: flex;
  align-items: center;
  /*justify-content: space-between;*/
  padding: 4px 0;
  flex-wrap: wrap;
  cursor: grab;
  border-bottom: 1px solid #cbcbcb;

  .handle {
    padding: 0 6px 0 8px;
  }
}

.block__time-gap {
  position: absolute;
  top: -24px;
  width: 85px;
  padding-bottom: 6px;
  text-align: center;
  font-style: italic;
}

.block__time-wrap {
  cursor: default;
  //margin-right: auto;
  padding-left: 6px;
}

.block--disabled {
  .block__time,
  .block__header,
  .block__v-del {
    cursor: default;
  }
}

.block__time {
  display: inline-block;
  cursor: pointer;
}

.block__duration {
  text-align: center;
  font-style: italic;
  font-weight: 400;
  padding-right: 6px;
  display: flex;
  align-items: center;
}

.block__name {
  font-size: .85rem;
  font-weight: bold;
  overflow: hidden;
  padding: 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1 auto;
  display: flex;
  cursor: default;
  align-items: center;
}

.block__content-extra .block__desc {
  min-width: 60%;
}

.block__content-extra {
  //display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
  background-color: #f4f4f4;
  min-width: 168px;
  margin-right: -1px;
  margin-bottom: -1px;
  display: flex;
  flex-direction: column;

  label {
    display: block;
    text-align: center;
  }

  & > div {
    border-bottom: 1px solid #cbcbcb;
    padding: 5px 10px;
    background-color: #fff;

    position: relative;
    height: auto;
    min-height: 59px;
    font-size: small;
    overflow-y: auto;
    overflow-x: hidden;

    //padding: 3px 5px;
    border-right: 1px solid #cbcbcb;
    /*margin-right: -1px;*/

    flex: 1 1 25%;
  }

  @include media-breakpoint-up(sm) {
    //grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    flex-direction: row;
    //flex-wrap: wrap;

    /*& > div {
      min-width: 330px;
    }*/
  }
}

/* -------------------------------------------------------------- */

.block--method {
  border: 1px solid #cbcbcb;
}

.block__duration {

}

.block--method-mini {
  font-size: 14px;
  min-height: 24px;
  height: auto !important;
  border: none;
  margin: 0 10px;
  background-color: transparent;

  .editable-input {
    font-size: 14px;
  }

  .block__duration {
    background-color: #fff;
    min-width: 22px;
  }

  .block__name {
    background-color: #fff;
    padding: 0 6px;
  }

  &.block--has-time-gap {
    margin-top: 20px !important;
  }

  .block__time-gap {
    top: -18px;
    text-align: right;
    font-style: italic;
    padding-bottom: 0;
    right: 2px;
  }

  .block__time-wrap {
    height: 24px;
    width: 46px;
    background-color: #fff;
    text-align: center;
    line-height: 24px;
    padding-left: 0;
  }
}

/* -------------------------------------------------------------- */

.block--module {
  background: #d1def1;
  height: auto;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  border: none;
}

.block__content-module {

}

.block--module {
  padding-bottom: 10px;
}

.block--module-mini, .block--tracks-mini {
  padding-bottom: 5px;
  font-size: 14px;
  min-height: 53px;

  .editable-input {
    font-size: 14px;
  }

  .block__name {
    padding: 0 6px;
  }

  & > div > .block__time-wrap {
    height: 24px;
    width: 46px;
    text-align: center;
    line-height: 24px;
    padding-left: 0;
    margin-left: 10px;
  }

  & > div > .block__v-del {
    width: 18px;
  }
}

/* -------------------------------------------------------------- */

.block-module-group {
}

/* -------------------------------------------------------------- */

.block--tracks {
  background: #e2e2e2;
  margin-left: 0;
  margin-right: 0;
}

.block__content-tracks {

}

.block--tracks {
}

.block--tracks-mini {

}

/* -------------------------------------------------------------- */

.block--tracks {
  background: #e2e2e2;
}

.block-track {
  padding-bottom: 10px;

  .block {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.block--track-mini {
  .block-track__blocks {
    padding-left: 0;
    padding-right: 0;
    margin-top: 5px;
    min-height: 24px;
  }
}

.block-track__blocks {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  min-height: 50px;
}

/* -------------------------------------------------------------- */

.block--dummy {
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #4dc0b5;
  }
}
